<div class="dialog-main dialog-glass-card row">
  <div class="header">
    {{ "Info" | translate | async }}

    <div class="close-button" (click)="onClose()">
      <i class="fas fa-times"></i>
    </div>
  </div>
  <div>{{ "PARK_CLOSE_SELL1" | translate | async }}.</div>
  <br />
  <br />
  <br />
  <div *ngIf="this.dialogService.dialogDetail.value?.phoneNumber">
    {{ "PARK_CLOSE_SELL2" | translate | async }}
    <a href="{{ this.dialogService.dialogDetail.value.phoneNumber }}">{{
      "PARK_CLOSE_SELL3" | translate | async
    }}</a>
    {{ "PARK_CLOSE_SELL4" | translate | async }}

    <a href="{{ this.dialogService.dialogDetail.value.phoneNumber }}">
      {{ this.dialogService.dialogDetail.value.phoneNumber }}</a
    >
    {{ "PARK_CLOSE_SELL5" | translate | async }}
  </div>
  <br />
  <br />
  <br />

  <div>
    {{ "PARK_CLOSE_SELL6" | translate | async }}
  </div>
</div>
