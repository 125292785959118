import { AbstractControl, ValidationErrors } from '@angular/forms';

export class RequiredValidators {
  static isRequired(control: AbstractControl): ValidationErrors | null {
    if (control.value == null || control.value.trim() == '') {
      return { required: true };
    } else {
      return null;
    }
  }
}
