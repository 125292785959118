import { ConfigService } from 'src/app/initializer/config.service';
import { Injectable, NgZone } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  firstValueFrom,
  of,
  switchMap,
  takeUntil,
  toArray,
} from 'rxjs';
import {
  Invoice,
  POS_SAVECHECK_Request,
} from 'src/app/models/pos/SP_POS_SAVECHECK_Request.model';
import {
  BasketTicketItem,
  Basket,
  BasketConcertItem,
  BasketExtraProductItem,
} from 'src/app/models/(basket)/Basket.model';
import { CreditCard } from 'src/app/models/pos/creditcard.model';
import { TcValidators } from 'src/app/validators/tc.validators';
import { ApiService, BaseRequest } from '../core/api.service';
import { AppService } from '../core/app.service';
import { SavecheckResponse } from 'src/app/models/pos/SP_POS_SAVECHECK_Response.model';
import { ToastrService } from 'ngx-toastr';
import { ExtraProduct } from 'src/app/models/(item)/extra-product/ExtraProduct';
import * as moment from 'moment';
import {
  PromotionList,
  PromotionWithStatus,
} from 'src/app/models/(AllData)/Promotion.model';
import { QuotaControlRequest } from 'src/app/models/(item)/concert/QuotaControlRequest.model';
import { ToastrServiceCore } from '../core/toastr.service';
import { RequiredValidators } from 'src/app/validators/required.validators';

export class GroupIndexMap {
  groupIndex: number;
  order: number;
  ticketIndex: Array<number>;
}
interface communication {
  NAME: string;
  LNAME: string;
  PHONE: string;
  EMAIL: string;
  ADDRESS: string;
}

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  basket$: BehaviorSubject<Basket> = new BehaviorSubject(null); // biletlerin tutulduğu field
  isOpenCreditCard$: BehaviorSubject<boolean> = new BehaviorSubject(null); // biletlerin tutulduğu field

  creditCardDetail$: BehaviorSubject<CreditCard>; // kredi kartı bilgilerinin tutulduğu field

  communicationForm: FormGroup = new FormGroup({
    NAME: new FormControl('', [RequiredValidators.isRequired]),
    LNAME: new FormControl('', [RequiredValidators.isRequired]),
    PHONE: new FormControl('', RequiredValidators.isRequired),
    EMAIL: new FormControl('', [
      RequiredValidators.isRequired,
      Validators.email,
    ]),
    ADDRESS: new FormControl(''),
  });

  invoiceForm: FormGroup = new FormGroup({
    NAME: new FormControl('', [RequiredValidators.isRequired]),
    LNAME: new FormControl('', [RequiredValidators.isRequired]),
    ADDRESS: new FormControl('', [RequiredValidators.isRequired]),
    CITY: new FormControl('', [RequiredValidators.isRequired]),
    CITYSUB: new FormControl({ value: '', disabled: true }, [
      RequiredValidators.isRequired,
    ]),
    TAXNUMBER: new FormControl('', [RequiredValidators.isRequired]),
    PHONE: new FormControl('', [RequiredValidators.isRequired]),
    EMAIL: new FormControl('', [
      RequiredValidators.isRequired,
      Validators.email,
    ]),
    ZIPCODE: new FormControl('', [RequiredValidators.isRequired]),
  });
  addTcFormControl() {
    this.communicationForm.addControl(
      'IDENTITYNO',
      new FormControl('', [
        RequiredValidators.isRequired,
        Validators.minLength(11),
        Validators.maxLength(11),
        Validators.pattern('[0-9]*'),
        TcValidators.isValidTc,
      ]),
    );
  }

  constructor(
    protected apiService: ApiService,
    protected appService: AppService,
    public router: Router,
    private toastrServiceCore: ToastrServiceCore,
    private configService: ConfigService,
  ) {
    this.basket$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (
        window.location.pathname == '/basket' &&
        (!data || !this.calcBasketItem())
      ) {
        this.toastrServiceCore.error(
          'No product was found in your cart. Please add product.',
        );
        this.router.navigate(['/']);
      }
    });
  }
  private destroy$ = new Subject<void>();
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  // TICKET
  getKvkkAgreement() {
    return this.apiService.getAggrement(7).toPromise();
  }
  calcBasketItem() {
    if (!this.basket$.value) return false;
    else if (
      this.basket$.value.ITEMS.TICKETITEMS.length +
        this.basket$.value.ITEMS.CONCERTITEMS.length ==
      0
    )
      return false;
    else return true;
  }
  addTicket(ticket: BasketTicketItem) {
    if (!this.basket$.value) {
      let BasketTicketsTemp: Basket = {
        TOTALBASKETPRICE: ticket.BASKETDETAIL.TOTALPRICE,
        CURRENCY: ticket.CURRENCY,
        CURRENCYID: ticket.CURRENCYID,
        CHECKDATE: ticket.BASKETDETAIL.CHECKDATE,
        CREATEDATE: ticket.BASKETDETAIL.CREATEDATE,
        ITEMS: {
          TICKETITEMS: [ticket],
          CONCERTITEMS: [],
          EXTRAPRODUCTITEMS: [],
        },
      };
      this.basket$.next(BasketTicketsTemp);
    } else {
      let BasketTicketsTemp = this.basket$?.value;
      BasketTicketsTemp.TOTALBASKETPRICE += ticket.BASKETDETAIL.TOTALPRICE;
      if (this.hasTicketIndex(ticket) > -1) {
        let index = this.hasTicketIndex(ticket);
        BasketTicketsTemp.ITEMS.TICKETITEMS[index].BASKETDETAIL.TOTALPRICE +=
          ticket.BASKETDETAIL.TOTALPRICE;
        BasketTicketsTemp.ITEMS.TICKETITEMS[index].BASKETDETAIL.ADULTCOUNT +=
          ticket.BASKETDETAIL.ADULTCOUNT;
        BasketTicketsTemp.ITEMS.TICKETITEMS[index].BASKETDETAIL.CHILDRENCOUNT +=
          ticket.BASKETDETAIL.CHILDRENCOUNT;
      } else {
        BasketTicketsTemp.ITEMS.TICKETITEMS.push(ticket);
      }
      this.basket$.next(BasketTicketsTemp);
    }
  }
  hasTicketIndex(ticket: BasketTicketItem) {
    let BasketTicketsTemp = this.basket$?.value;
    let index = BasketTicketsTemp.ITEMS.TICKETITEMS.findIndex((detail) => {
      return (
        detail.BASKETGROUPNO == ticket.BASKETGROUPNO &&
        detail.BASKETDETAIL.CHECKDATE == ticket.BASKETDETAIL.CHECKDATE &&
        detail.BASKETDETAIL.CREATEDATE == ticket.BASKETDETAIL.CREATEDATE &&
        detail.TICKETNAME == ticket.TICKETNAME &&
        detail.SUBTICKETS == ticket.SUBTICKETS
      );
    });
    return index;
  }
  deleteTicketItem(BasketTicket: BasketTicketItem) {
    let BasketTicketsTemp = this.basket$?.value;
    BasketTicketsTemp.ITEMS.TICKETITEMS =
      BasketTicketsTemp.ITEMS.TICKETITEMS.filter((item) => {
        return item != BasketTicket;
      });
    if (BasketTicketsTemp.ITEMS.TICKETITEMS.length == 0) {
      if (!this.calcBasketItem()) {
        this.basket$.next(null);
        return;
      }
    }
    this.basket$.next(BasketTicketsTemp);
  }
  // CONCERT
  async addConcert(
    concert: BasketConcertItem,
    promotion?: PromotionWithStatus,
    redirectActive: boolean = true,
  ) {
    let BasketTicketsTemp: Basket;
    if (!this.basket$.value) {
      BasketTicketsTemp = {
        TOTALBASKETPRICE: concert.BASKETDETAIL.TOTALPRICE,
        CURRENCY: concert.CURRENCY,
        CURRENCYID: concert.CURRENCYID,
        CHECKDATE: concert.BASKETDETAIL.CHECKDATE,
        CREATEDATE: concert.BASKETDETAIL.CREATEDATE,
        ITEMS: {
          TICKETITEMS: [],
          CONCERTITEMS: [concert],
          EXTRAPRODUCTITEMS: [],
        },
      };
    } else {
      if (this.basket$.value.CHECKDATE != concert.BASKETDETAIL.CHECKDATE) {
        this.toastrServiceCore.error(
          'Concerts with Different Dates Cannot be Added to the Cart',
        );
        return;
      }
      BasketTicketsTemp = this.basket$?.value;
      BasketTicketsTemp.TOTALBASKETPRICE += concert.BASKETDETAIL.TOTALPRICE;
      if (this.hasConcertIndex(concert) > -1) {
        let index = this.hasConcertIndex(concert);
        BasketTicketsTemp.ITEMS.CONCERTITEMS[index].BASKETDETAIL.TOTALPRICE +=
          concert.BASKETDETAIL.TOTALPRICE;

        BasketTicketsTemp.ITEMS.CONCERTITEMS[index].BASKETDETAIL.COUNT +=
          concert.BASKETDETAIL.COUNT;
      } else {
        BasketTicketsTemp.ITEMS.CONCERTITEMS.push(concert);
      }
    }

    this.basket$.next(BasketTicketsTemp);
    this.toastrServiceCore.success('The Concert Was Added to the Cart');
    if (promotion && promotion.status) {
      let tempPromotion: PromotionList = {
        index: this.basket$.value.ITEMS.CONCERTITEMS.length - 1,
        promotion: promotion,
        selectPromotionCode: promotion.selectPromotionCode,
      };
      let promotionList = this.appService.promotionList$.value || [];
      promotionList.push(tempPromotion);
      this.appService.promotionList$.next(promotionList);
    }
    if (redirectActive) this.router.navigate(['/extraProduct']);
  }
  hasConcertIndex(concert: BasketConcertItem) {
    let BasketTicketsTemp = this.basket$?.value;
    let index = BasketTicketsTemp.ITEMS.CONCERTITEMS.findIndex((detail) => {
      return (
        detail.BASKETGROUPNO == concert.BASKETGROUPNO &&
        detail.BASKETDETAIL.CHECKDATE == concert.BASKETDETAIL.CHECKDATE &&
        detail.BASKETDETAIL.CREATEDATE == concert.BASKETDETAIL.CREATEDATE &&
        detail.SUBTICKETID == concert.SUBTICKETID &&
        detail.CURRENCY == concert.CURRENCY &&
        detail.PRICE == concert.PRICE &&
        detail.TICKETTYPEID == concert.TICKETTYPEID
      );
    });
    return index;
  }

  // EXTRA
  addExtraProduct(extraProduct: ExtraProduct, count: number) {
    let basket = this.basket$.value;
    let extraProducts: BasketExtraProductItem[] =
      basket?.ITEMS?.EXTRAPRODUCTITEMS;

    let index = extraProducts.findIndex((item) => {
      return item.ID == extraProduct.ID;
    });
    if (index > -1) {
      extraProducts[index].BASKETDETAIL.COUNT += count;
      extraProducts[index].BASKETDETAIL.TOTALPRICE =
        extraProducts[index].BASKETDETAIL.COUNT *
        extraProducts[index].BASKETDETAIL.TOTALPRICE;
      basket.TOTALBASKETPRICE += extraProducts[index].BASKETDETAIL.TOTALPRICE;
    } else {
      let basketExtraProductItem: BasketExtraProductItem = {
        ...extraProduct,
        BASKETDETAIL: {
          COUNT: count,
          TOTALPRICE: extraProduct.PRICE * count,

          CREATEDATE: basket.CREATEDATE,
          CHECKDATE: basket.CHECKDATE,
        },
      };
      basket.TOTALBASKETPRICE += basketExtraProductItem.BASKETDETAIL.TOTALPRICE;
      extraProducts.push(basketExtraProductItem);
    }

    this.basket$.next(basket);
    this.toastrServiceCore.success(
      'Extra Products have Been Added to the Cart',
    );
  }

  paidReservationNotReceived(
    BasketTickets: Basket,
    invoice: Invoice,
    communication: communication,
  ) {
    this.basket$.next(BasketTickets);
    this.invoiceForm.patchValue(invoice);
    this.communicationForm.patchValue(communication);
    this.invoiceForm.controls?.['CITYSUB'].enable();
    this.router.navigate(['/basket']);
    this.isOpenCreditCard$.next(true);
  }

  basketSave(mappingApiModelBasket: POS_SAVECHECK_Request[]) {
    return this.basketSaveRequest(mappingApiModelBasket);
    // const basketArray = mappingApiModelBasket.map((basketApiItem) => {
    //   let resp = this.basketSaveRequest(basketApiItem);
    //   return resp;
    // });

    // const resp = firstValueFrom(
    //   combineLatest(basketArray).pipe(
    //     switchMap((res) => {
    //       return res.map((item) => {
    //         return item[0][0];
    //       });
    //     }),
    //     toArray()
    //   )
    // );

    // return of(resp);
  }
  basketSaveRequest(
    basketApiItem: POS_SAVECHECK_Request[],
  ): Observable<SavecheckResponse> {
    return this.apiService.basketSave(basketApiItem);
  }
  deleteExtraProduct(extraProduct: ExtraProduct) {
    let basket = this.basket$.value;
    let extraProducts: BasketExtraProductItem[] =
      basket?.ITEMS?.EXTRAPRODUCTITEMS;

    let index = extraProducts.findIndex((item) => {
      return item.ID == extraProduct.ID;
    });
    if (index > -1) {
      if (extraProducts[index].BASKETDETAIL.COUNT == 1) {
        basket.TOTALBASKETPRICE -= extraProducts[index].BASKETDETAIL.TOTALPRICE;
        extraProducts.splice(index, 1);
      } else {
        extraProducts[index].BASKETDETAIL.TOTALPRICE -=
          extraProducts[index].PRICE;
        extraProducts[index].BASKETDETAIL.COUNT -= 1;
      }
    }
    this.basket$.next(basket);
    this.toastrServiceCore.warning(
      'The Extra Product Has Been Deleted from the Cart',
    );
  }
  deleteItem(type: string, index: number) {
    let basket = this.basket$.value;
    if (type == 'BasketTicketItem') {
      basket.TOTALBASKETPRICE -=
        basket.ITEMS.TICKETITEMS[index].BASKETDETAIL.TOTALPRICE;
      basket.ITEMS.TICKETITEMS.splice(index, 1);
    }
    if (type == 'BasketConcertItem') {
      basket.TOTALBASKETPRICE -=
        basket.ITEMS.CONCERTITEMS[index].BASKETDETAIL.TOTALPRICE;
      basket.ITEMS.CONCERTITEMS.splice(index, 1);
      let promotionList = this.appService.promotionList$.value;

      if (promotionList) {
        let indexProm = promotionList.findIndex((promotion) => {
          return promotion.index == index;
        });
        if (indexProm > -1) {
          promotionList.splice(indexProm, 1);
          if (promotionList.length == 0) promotionList = null;
          this.appService.promotionList$.next(promotionList);
        }
      }
    }
    if (type == 'BasketExtraProductItem') {
      basket.TOTALBASKETPRICE -=
        basket.ITEMS.EXTRAPRODUCTITEMS[index].BASKETDETAIL.TOTALPRICE;
      basket.ITEMS.EXTRAPRODUCTITEMS.splice(index, 1);
    }
    if (
      basket.ITEMS.TICKETITEMS.length == 0 &&
      basket.ITEMS.CONCERTITEMS.length == 0
    ) {
      basket = null;
    }

    this.toastrServiceCore.success('The Product Was Deleted from the Cart');
    this.basket$.next(basket);
  }
  enableInvoiceForm() {
    this.invoiceForm.enable();
    this.invoiceForm.updateValueAndValidity();
  }
  disableInvoiceForm() {
    this.invoiceForm.disable();
    this.invoiceForm.updateValueAndValidity();
  }

  async quotaControl(item: QuotaControlRequest) {
    let req: BaseRequest<QuotaControlRequest> = {
      Action: 'Execute',
      Object: 'SP_POS_PARK_FIND_USEDQUOTA_FORTICKETS',
      Parameters: item,
    };
    return await this.apiService.postRequestInsertHotelId(req).toPromise();
  }
  async getExchangeRate(FOREIGNCURRENCYID: number) {
    return await this.apiService.getExchangeRate(FOREIGNCURRENCYID).toPromise();
  }
}
