import { Injectable } from '@angular/core';
import { AppService } from '../core/app.service';
import { ApiService } from '../core/api.service';
import * as moment from 'moment';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MainItemService {
  constructor(private appService: AppService, private apiService: ApiService) {}
  async getSettings() {
    this.appService.progressBool();
    await this.apiService
      .getSettings()
      .then((data) => {
        data = JSON.parse(data[0][0].Return);

        if (!data) {
          this.appService.progressBool();

          return;
        }
        // localStorage.setItem(
        //   'settings',
        //   JSON.stringify(JSON.parse(data.SETTINGS[0].SETTINGS))
        // );
        // localStorage.setItem(
        //   'companyInfo',
        //   JSON.stringify(data.COMPANYINFO[0])
        // );

        this.appService.setWebsite(
          JSON.parse(data.SETTINGS[0].SETTINGS),
          data.COMPANYINFO[0],
          data.LANGUAGES
        );
        this.appService.progressBool();
      })
      .catch((error) => {
        console.log('Error', error);
        this.appService.progressBool();
      });
  }
}
