export class Settings {
  CASHIERID: string;
  DEPID: string;
  FOREIGNRATECODEID: string;
  LOCALRATECODEID: string;
  PAYDEPID: string;
  POSCARDID: string;
  WAITERID: string;
  USEBASKETGROUPING: number;
  CLOSE_SELL: string;
  TICKET_PAGE_BACKGROUND_URL: string;
  PHONE_NUMBER: string;
  EMAIL: string;
  CONCERTSALEACTIVE: boolean;
  ENTRANCETICKETACTIVE: boolean;
  EXTRAPRODUCTACTIVE: boolean;
  ISLOGINACTIVE: string;
  ISSHOWSELECTTICKETDATE: boolean;
  PARKMEMBER_PRODUCTID: number[]; // sezonluk üyelik eşleştirmek için ID
  PRIMARYCOLOR: string;
  SECONDARYCOLOR: string;
  DEFAULTFOREIGNROUTE: boolean;
  ISCONCERTTICKETNAMEPERSON: boolean;
  ENTRANCETICKETDESIGN: string;
  ISACTIVECONCERTGROUPED: boolean;
  NAVBARCONCERTLABEL: string;
  HIDEFOOOTERPAGES: string[];
  ISSHOWPROMOTIONCONTROLACTIVE: boolean;
  constructor(settings: Settings) {
    this.CASHIERID = settings?.CASHIERID;
    this.DEPID = settings?.DEPID;
    this.FOREIGNRATECODEID = settings?.FOREIGNRATECODEID;
    this.LOCALRATECODEID = settings?.LOCALRATECODEID;
    this.PAYDEPID = settings?.PAYDEPID;
    this.POSCARDID = settings?.POSCARDID;
    this.WAITERID = settings?.WAITERID;
    this.USEBASKETGROUPING = settings?.USEBASKETGROUPING || 0;
    this.CLOSE_SELL = settings?.CLOSE_SELL || '0';
    this.TICKET_PAGE_BACKGROUND_URL = settings?.TICKET_PAGE_BACKGROUND_URL;
    this.PHONE_NUMBER = settings?.PHONE_NUMBER;
    this.EMAIL = settings?.EMAIL;
    this.CONCERTSALEACTIVE =
      (settings?.CONCERTSALEACTIVE as any as string) == '1' ? true : false;
    this.ENTRANCETICKETACTIVE =
      (settings?.ENTRANCETICKETACTIVE as any as string) == '1' ? true : false;
    this.EXTRAPRODUCTACTIVE =
      (settings?.EXTRAPRODUCTACTIVE as any as string) == '1' ? true : false;
    this.ISACTIVECONCERTGROUPED = settings?.ISACTIVECONCERTGROUPED || false;

    this.ISLOGINACTIVE = settings?.ISLOGINACTIVE || '0';
    this.ISSHOWSELECTTICKETDATE = settings?.ISSHOWSELECTTICKETDATE || false;
    this.PARKMEMBER_PRODUCTID = settings?.PARKMEMBER_PRODUCTID || null;
    this.DEFAULTFOREIGNROUTE = settings?.DEFAULTFOREIGNROUTE || false;
    this.PRIMARYCOLOR = settings?.PRIMARYCOLOR || '#203239';
    this.SECONDARYCOLOR = settings?.SECONDARYCOLOR || '#e9dac1';
    this.ISCONCERTTICKETNAMEPERSON =
      settings?.ISCONCERTTICKETNAMEPERSON || false;
    this.ENTRANCETICKETDESIGN =
      settings?.ENTRANCETICKETDESIGN &&
      ENTRANCETICKETDESIGNTYPE.includes(settings?.ENTRANCETICKETDESIGN)
        ? settings?.ENTRANCETICKETDESIGN
        : 'default';
    this.NAVBARCONCERTLABEL = ['Concerts', 'Tickets', 'Events'].includes(
      settings?.NAVBARCONCERTLABEL,
    )
      ? settings?.NAVBARCONCERTLABEL
      : 'Concerts';
    this.HIDEFOOOTERPAGES = Array.isArray(settings?.HIDEFOOOTERPAGES)
      ? settings?.HIDEFOOOTERPAGES
      : typeof settings?.HIDEFOOOTERPAGES == 'string'
      ? (settings?.HIDEFOOOTERPAGES as string)?.split(',')
      : [];
    this.ISSHOWPROMOTIONCONTROLACTIVE =
      settings?.ISSHOWPROMOTIONCONTROLACTIVE || false;
  }
}
const ENTRANCETICKETDESIGNTYPE = ['biletantalya', 'default'];
