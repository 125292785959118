import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResponse, BaseRequest } from '../services/core/api.service';
import { environment } from 'src/environments/environment';
import { AppService } from '../services/core/app.service';
@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(
    private http: HttpClient,
    private appService: AppService,
  ) {}
  private hotelId$ = new BehaviorSubject<number | null>(null);
  private bearerToken$ = new BehaviorSubject<string | null>(null);
  get hotelId() {
    if (window.location.hostname == 'localhost') {
      return 28818;
      return 25861;
      return 25860;
      return 1029;
    }
    return this.hotelId$.getValue();
  }
  get bearerToken() {
    return this.bearerToken$.getValue();
  }
  async init() {
    await this.appService.setProject();
    return await this.findHotelId();
  }
  async findHotelId() {
    if (this.hotelId) {
      if (this.hotelId != this.appService.hotelId$.getValue()) {
        localStorage.setItem('hotelId', this.hotelId.toString());

        this.appService.hotelId$.next(this.hotelId);
      }
      return this.hotelId;
    }
    if (localStorage.getItem('hotelId')) {
      this.hotelId$.next(+localStorage.getItem('hotelId'));
      this.appService.hotelId$.next(+localStorage.getItem('hotelId'));
    } else {
      const req: BaseRequest<{ CNAME: string }> = {
        Action: 'Execute',
        Object: 'SP_FIND_HOTELDOMAIN',
        Parameters: {
          CNAME:
            window.location.hostname != 'localhost'
              ? window.location.hostname
              : 'etkinlik.nefesorman.com', //window.location.host,
        },
      };
      const res = await this.postRequest(req).toPromise();
      const id = res[0][0].ID;
      this.hotelId$.next(id);

      this.appService.hotelId$.next(id);

      localStorage.setItem('hotelId', id.toString());
      return res;
    }
  }
  postRequest(request: BaseRequest<any>): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    headers.append('disableRetryOnError', '0');
    try {
      return this.http.post<ApiResponse | string | any>(
        this.appService.project$.value.API_URL +
          request.Action +
          '/' +
          request.Object,
        JSON.stringify(request),
        {
          headers,
        },
      );
    } catch (error) {
      return error;
    }
  }
}
