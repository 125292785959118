import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { AppService } from '../services/core/app.service';

@Injectable({
  providedIn: 'root',
})
export class ExtraProductGuard implements CanActivate {
  constructor(public router: Router, private appService: AppService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.init(next);
  }

  async init(next: ActivatedRouteSnapshot) {
    if (
      !this.appService.extraProducts$.value ||
      this.appService.extraProducts$.value?.length == 0 ||
      !this.appService.settings$.value?.EXTRAPRODUCTACTIVE
    ) {
      if (!this.appService.isShowExtraServices) {
        this.appService.isShowExtraServices = true;
      }
      this.router.navigate(['/basket']);
      return false;
    }
    return true;
  }
}
