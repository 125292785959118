import { AgreementType } from './agreement';

export type WebPageModel = {
  name: string;
  path: string;
  agreementType: AgreementType;
};

export const WebPages: WebPageModel[] = [
  {
    name: 'FOOTER_PRIVACY_AGREEMENT',
    path: 'GizlilikSozlesmesi',
    agreementType: AgreementType.PrivacyAgreement,
  },
  {
    name: 'KVKK',
    path: 'KVKK',
    agreementType: AgreementType.KVKK,
  },

  {
    name: 'FOOTER_SALE_AGREEMENT',
    path: 'SatisSozlesmesi',
    agreementType: AgreementType.SaleAgreement,
  },

  {
    name: 'CancellationAgreement',
    path: 'IadeSozlesmesi',
    agreementType: AgreementType.CancellationAgreement,
  },
  {
    name: 'AboutUs',
    path: 'AboutUs',
    agreementType: AgreementType.AboutUs,
  },
];
