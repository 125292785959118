import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntil, Subject } from 'rxjs';
import { FormService } from 'src/app/services/core/form.service';

export interface optionsObjectModel {
  textProperty: string;
  valueProperty: string;
  options: any[];
}

@Component({
  selector: 'basic-option',
  templateUrl: './basic-option.component.html',
  styleUrls: ['./basic-option.component.css'],
})
export class BasicOptionComponent implements OnInit {
  @Input() optionsText: string[];
  @Input() optionsObject: optionsObjectModel;

  @Input() inputLabel: string;
  @Input() hint: string;
  @Input() isDisable: boolean = false;
  @Input() isSubmit: boolean = false;

  @Output() onChange = new EventEmitter<any>();
  constructor(public formService: FormService) {}

  ngOnInit() {
    console.log(this.optionsObject);
  }
  private destroy$ = new Subject<void>();
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  change(value) {
    this.onChange.emit(value);
  }
  goster() {
    console.log(this.optionsObject);
    console.log(this.optionsText);
  }
}
