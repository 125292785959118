<div class="main-footer">
  <div class="footerTop Top">
    <ng-container *ngIf="!isMobile; else mobile">
      <div class="topItem">
        <ol>
          <li *ngFor="let webpage of webpages$ | async">
            <a [routerLink]="['/WebPage', webpage.path]">{{
              webpage.name | translate | async
            }}</a>
          </li>
        </ol>
      </div>
    </ng-container>
    <ng-template #mobile>
      <div class="topItem">
        <div class="row footItem">
          <div
            class="col-6"
            *ngFor="let webpage of webpages$.value.slice(0, 2)"
          >
            <a [routerLink]="['/WebPage', webpage.path]">{{
              webpage.name | translate | async
            }}</a>
          </div>
        </div>
        <div class="row footItem">
          <div
            class="col-6"
            *ngFor="let webpage of webpages$.value.slice(2, 4)"
          >
            <a [routerLink]="['/WebPage', webpage.path]">{{
              webpage.name | translate | async
            }}</a>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <div>
    <div class="footerCenterTopCon container">
      <div
        class="footerCenterItem ng-star-inserted"
        style="grid-column: span 4 / auto"
      >
        <div class="footerCenterTitle">
          {{ "Links" | translate | async }}
        </div>
        <ul class="footerCenterLinks">
          <li>
            <a [routerLink]="['']">{{ "Home" | translate | async }}</a>
          </li>

          <!---->
        </ul>
      </div>

      <div
        class="footerCenterItem footerContact"
        style="grid-column: span 4 / auto"
      >
        <div class="footerCenterTitle">
          {{ "Contact" | translate | async }}
        </div>
        <!---->
        <div
          class="footerCenterText ng-star-inserted"
          *ngIf="this.appService.settings$.value?.PHONE_NUMBER"
        >
          {{ "Phone" | translate | async }} :
          <a href="tel:{{ this.appService.settings$.value?.PHONE_NUMBER }}">{{
            this.appService.settings$.value?.PHONE_NUMBER
          }}</a>
        </div>
        <!---->
        <!---->
        <div
          class="footerCenterText ng-star-inserted"
          *ngIf="this.appService.settings$.value?.EMAIL"
        >
          {{ "Email" | translate | async }} :
          <a href="mailto:{{ this.appService.settings$.value?.EMAIL }}"
            >{{ this.appService.settings$.value?.EMAIL }}
          </a>
        </div>
        <!---->
      </div>
    </div>
  </div>
  <div class="bottomMain">
    <div class="container Bottom">
      <div class="footer">
        <div class="social">
          <a
            target="_blank"
            [href]="appService.companyInfo$.value?.FBPAGEURL"
            *ngIf="appService.companyInfo$.value?.FBPAGEURL"
          >
            <i class="fa-brands fa-facebook fa-2x" style="color: #3b5998"></i
          ></a>
          <a
            target="_blank"
            [href]="appService.companyInfo$.value?.WHATSAPP"
            *ngIf="appService.companyInfo$.value?.WHATSAPP"
          >
            <i class="fa-brands fa-whatsapp fa-2x" style="color: #42de5d"></i
          ></a>
        </div>

        <div class="footerLogosRights">
          <!---->
          <div class="footerLogo footerVisa ng-star-inserted">
            <img
              width="82px"
              height="25px"
              src="https://erspublic.blob.core.windows.net/test/17212506-75d6-331-8220-2f6106a89000.png"
            />
          </div>
          <!---->
          <div class="footerLogo footerMaster ng-star-inserted">
            <img
              width="46px"
              height="28px"
              src="https://erspublic.blob.core.windows.net/test/17212513-f808-601-823c-9fccb7501000.png"
            />
          </div>
          <!---->
          <div class="footerLogo footerTursab ng-star-inserted">
            <img
              width="82px"
              height="25px"
              src="https://erspublic.blob.core.windows.net/test/17212526-5e2d-1307-89a9-48a26cd6c000.png"
            />
          </div>
          <!---->
          <!---->
        </div>
      </div>
    </div>
  </div>
</div>
