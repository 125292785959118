import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { InsertionDirective } from '../../directives/insertion.directive';
import { DialogAdvertisement } from '../example/dialog-advertisement/dialog-advertisement.component';
import { ReservationItemModule } from 'src/app/components/(items)/ticket/reservation-item/reservation-item.module';
import { RouterModule } from '@angular/router';
import { CloseSellComponent } from '../example/close-sell/close-sell.component';
import { TextDialogComponent } from '../example/text-dialog/text-dialog.component';
import { TranslatePipeModule } from 'src/app/pipes/translate.module';

@NgModule({
  declarations: [
    DialogComponent,
    DialogAdvertisement,
    InsertionDirective,
    CloseSellComponent,
    TextDialogComponent,
  ],
  imports: [
    CommonModule,
    TranslatePipeModule,
    ReservationItemModule,
    RouterModule,
  ],
  exports: [
    DialogComponent,
    DialogAdvertisement,
    CloseSellComponent,
    TextDialogComponent,
  ],
  // providers: [DialogService],
  entryComponents: [DialogComponent],
})
export class DialogModule {}
