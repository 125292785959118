import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DialogRef } from 'src/app/dialog/config/dialog-ref';
import { DialogService } from 'src/app/dialog/service/dialog.service';

@Component({
  selector: 'app-close-sell',
  templateUrl: './close-sell.component.html',
  styleUrls: ['./close-sell.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CloseSellComponent implements OnInit {
  constructor(
    public dialog: DialogRef,
    protected dialogService: DialogService
  ) {
    console.log(this.dialogService.dialogDetail.value);
    console.log(this.dialogService.dialogDetail.value.phoneNumber);
    console.log(this.dialogService.dialogDetailConfig.value);
  }

  ngOnInit() {}
  onClose(): void {
    this.dialog.close('some value');
  }
}
