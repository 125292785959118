import { NgModule } from '@angular/core';
import { ImportModule } from 'src/app/import.module';
import { Loading7Component } from './loading7/loading7.component';

@NgModule({
  imports: [ImportModule],
  declarations: [Loading7Component],
  exports: [Loading7Component],
})
export class LoadingModule {}
