import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasketPreviewMainComponent } from './basket-preview-main.component';
import { BasketPreviewItemModule } from '../basket-preview-item/basket-preview-item.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, BasketPreviewItemModule, RouterModule],
  declarations: [BasketPreviewMainComponent],
  exports: [BasketPreviewMainComponent],
})
export class BasketPreviewMainModule {}
