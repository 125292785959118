<div class="main-container">
  <div class="header">
    <div class="header-title">3D Secure Ekranına Yönlendiriliyorsunuz.</div>
    <!-- <div class="header-title">{{ "LBL_REDIRECT_TO_BANK"| translate | async }}.</div> -->

    <div class="button-close" (click)="onClose()">
      <a class="close"><i class="fa-solid fa-x"></i></a>
    </div>
  </div>
  <div class="loading">
    <ng-container>
      <div
        style="display: flex; flex-direction: column; align-items: center"
      ></div>
      <div>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 1366 768"
          xml:space="preserve"
        >
          <circle
            stroke="dimgray"
            stroke-width="2"
            fill="gray"
            cx="50%"
            cy="50%"
            r="14%"
          />
          <g>
            <path
              class="st0 grey"
              d="M772.5,347c-6.2-14-2.4-29.5,8.4-35.8c1.1-0.6,1.4-2.2,0.8-3.7l-8.5-19.1c-3.4-7.6-11.2-11.4-17.5-8.6
                l-201,89.5c-6.3,2.8-8.7,11.2-5.3,18.8c0,0,6.4,14.3,8.5,19.1c0.6,1.4,2,2.2,3.3,1.8c12-3.8,26,3.7,32.3,17.7s2.4,29.5-8.4,35.8
                c-1.1,0.6-1.4,2.2-0.8,3.7l8.5,19.1c3.4,7.6,11.2,11.4,17.5,8.6l201-89.5c6.3-2.8,8.7-11.2,5.3-18.8l-8.5-19.1
                c-0.6-1.4-2-2.2-3.3-1.8C792.8,368.5,778.7,361,772.5,347z"
            ></path>
            <path
              class="st1 blue"
              d="M772.5,347c-6.2-14-2.4-29.5,8.4-35.8c1.1-0.6,1.4-2.2,0.8-3.7l-8.5-19.1c-3.4-7.6-11.2-11.4-17.5-8.6
                l-201,89.5c-6.3,2.8-8.7,11.2-5.3,18.8c0,0,6.4,14.3,8.5,19.1c0.6,1.4,2,2.2,3.3,1.8c12-3.8,26,3.7,32.3,17.7s2.4,29.5-8.4,35.8
                c-1.1,0.6-1.4,2.2-0.8,3.7l8.5,19.1c3.4,7.6,11.2,11.4,17.5,8.6l201-89.5c6.3-2.8,8.7-11.2,5.3-18.8l-8.5-19.1
                c-0.6-1.4-2-2.2-3.3-1.8C792.8,368.5,778.7,361,772.5,347z"
            ></path>
          </g>
        </svg>
      </div>
    </ng-container>
  </div>

  <ng-container #container></ng-container>
  <ng-template #template let-html>
    <div class="html-dialog-container">
      <iframe frameborder="0">
        <div
          style="margin-right: 40px"
          [innerHTML]="html"
          [taCoreRunScripts]="!!html"
        ></div>
      </iframe>
    </div>
  </ng-template>
</div>
