import {
  Injectable,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  EmbeddedViewRef,
  ComponentRef,
  Type,
} from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { DialogComponent } from '../components/dialog/dialog.component';
import { DialogModule } from '../components/dialog/dialog.module';
import { DialogConfig } from '../config/dialog-config';
import { DialogInjector } from '../config/dialog-injector';
import { DialogRef } from '../config/dialog-ref';

import { DialogDetail } from '../model/DialogDetail';
import { DialogDetailConfig } from '../model/DialogDetailConfig';
@Injectable({
  providedIn: DialogModule,
})
export class DialogService {
  dialogComponentRef: ComponentRef<DialogComponent>;
  dialogDetailConfig = new BehaviorSubject<DialogDetailConfig>(null);
  dialogDetail = new BehaviorSubject<any>(null);
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  public open(
    componentType: Type<any>,
    config: DialogConfig,
    dialogDetailConfig: DialogDetailConfig,
    dialogDetail?: any
  ): DialogRef {
    const dialogRef = this.appendDialogComponentToBody(config);

    this.dialogDetailConfig = new BehaviorSubject(dialogDetailConfig);
    this.dialogDetail = new BehaviorSubject(dialogDetail);
    this.dialogComponentRef.instance.childComponentType = componentType;

    return dialogRef;
  }

  close() {}

  private appendDialogComponentToBody(config: DialogConfig): DialogRef {
    const map = new WeakMap();
    map.set(DialogConfig, config);

    const dialogRef = new DialogRef();
    map.set(DialogRef, dialogRef);

    const sub = dialogRef.afterClosed
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        // close the dialog
        this.removeDialogComponentFromBody();
        sub.unsubscribe();
      });

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(DialogComponent);
    const componentRef = componentFactory.create(
      new DialogInjector(this.injector, map)
    );
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.dialogComponentRef = componentRef;

    this.dialogComponentRef.instance.onClose
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.removeDialogComponentFromBody();
      });

    return dialogRef;
  }

  private removeDialogComponentFromBody(): void {
    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }
  private destroy$ = new Subject<void>();
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
