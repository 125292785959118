import { BreakpointObserver } from '@angular/cdk/layout';
import { AuthService } from 'src/app/services/app/auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BasketService } from 'src/app/services/app/basket.service';
import { AppService } from 'src/app/services/core/app.service';
import { takeUntil, Subject } from 'rxjs';
import { ToastrServiceCore } from 'src/app/services/core/toastr.service';
import { optionsObjectModel } from '../../(shareds)/basic-option/basic-option.component';
import { FormControl } from '@angular/forms';
import { TranslateService } from 'src/app/services/core/translate.service';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  // langs: optionsObjectModel = {
  //   textProperty: 'key',
  //   valueProperty: 'value',
  //   options: [
  //     {
  //       key: 'Türkçe',
  //       value: 'TR',
  //     },
  //     {
  //       key: 'English',
  //       value: 'en',
  //     },
  //   ],
  // };
  langFormControl: FormControl = new FormControl('TR');
  constructor(
    public basketService: BasketService,
    private toastrServiceCore: ToastrServiceCore,
    public appService: AppService,
    private router: Router,
    public authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    private translateService: TranslateService
  ) {}
  isShowBasketPanel: boolean = false;
  isMobile: boolean = false;
  @Input() SettingsPrimaryColor;
  @Input() SettingsSecondaryColor;
  ngOnInit() {
    this.breakpointObserver
      .observe(['(max-width: 599px)'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.isMobile = value.breakpoints['(max-width: 599px)'];
      });
    this.appService.settings$
      .pipe(takeUntil(this.destroy$))
      .subscribe((settings) => {
        if (
          settings &&
          settings?.ISLOGINACTIVE == '1' &&
          localStorage.getItem('user')
        ) {
          this.authService.userLogin(JSON.parse(localStorage.getItem('user')));
        } else if (localStorage.getItem('user')) {
          localStorage.removeItem('user');
        }
      });

    this.appService.language$
      .pipe(takeUntil(this.destroy$))
      .subscribe((lang) => {
        // if (this.langFormControl.value != lang)
        this.langFormControl.setValue(lang);
      });
    this.langFormControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((lang) => {
        if (this.appService.language$.value != lang)
          this.appService.setLang(lang);
        // this.appService.language$.next(lang);
      });
  }
  private destroy$ = new Subject<void>();
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  openNav() {
    document.getElementById('mySidebar')!.style.width = '250px';
    document.getElementById('main')!.style.marginLeft = '250px';
  }

  closeNav() {
    document.getElementById('mySidebar')!.style.width = '0';
    document.getElementById('main')!.style.marginLeft = '0';
  }

  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  colorPalet(primaryColor, secondaryColor) {
    const htmlElStyle = document.documentElement.style;
    htmlElStyle.setProperty('--primaryColor', primaryColor);
    htmlElStyle.setProperty('--secondaryColor', secondaryColor);
  }

  ShowBasketPanel() {
    if (!this.isShowBasketPanel && !this.basketService.basket$.value) {
      this.toastrServiceCore.error('There are no products in your cart.');
      return;
    }
    this.isShowBasketPanel = !this.isShowBasketPanel;
  }
  goToExtraProduct() {
    if (this.basketService.basket$.value)
      this.router.navigate(['/extraProduct']);
    else this.toastrServiceCore.error('There are no products in your cart.');
  }
  StopEvent(event) {
    event.stopPropagation();
  }
  routeBasketPreview() {
    if (
      !this.appService.isShowExtraServices &&
      !(this.appService.extraProducts$.value?.length > 0)
    ) {
      this.appService.isShowExtraServices = true;
    }

    if (this.appService.isShowExtraServices) {
      this.router.navigate(['/basket']);
    } else this.router.navigate(['/extraProduct']);
    this.isShowBasketPanel = false;
  }

  LogOut() {
    this.authService.userLogout();
  }

  getBasketItemLength() {
    return (
      this.basketService.basket$.value?.ITEMS.CONCERTITEMS.length +
      this.basketService.basket$.value?.ITEMS.EXTRAPRODUCTITEMS.length +
      this.basketService.basket$.value?.ITEMS.TICKETITEMS.length
    );
  }
}
