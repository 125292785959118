// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://4001.hoteladvisor.net/',
  FAVICON_URL:
    'https://www.elektraweb.com/wp-content/uploads/2019/06/favicon.ico',
  TITLE: 'Elektraweb',
  // HOTEL_ID: 25861,
  // dockerImageVersion: '${DOCKER_IMAGE_VERSION}',
  dockerVersion: '__DOCKER_VERSION__', // Placeholder

  AUTH_TOKEN_25861:
    'parkposapi#25861$5bd8b31b452eb08b690ea7397c5ee8fe514d999afff8dd51b1911f7c86b8820245dc82eac53c397aa18718474727274f2c7687825c0ad6a77bcd09bcae4b7569',
  AUTH_TOKEN_25860:
    'webapi#25860$34a61cc72d0aef7d7cd0d615a46ff1fb34f3a724fc76d3e0a261980f0bc5ded1f973ddc3d3c6fc2d59577e3f617ea2215cd88e287db907cb0a2378f60652762f',
  AUTH_TOKEN_28818:
    'webapi#28818$57cd790f20f546fbe8371fedf054084a47939d57dc15df21eb034d35038664af3a3653006f8cf59c4f97a94e532c656ce6b3796644dc90f6a205ba34c74a6703',
  AUTH_TOKEN_25149:
    'webapi#25149$218ea1dc40be9c8ae5f36cd58fa923802811addf5ef01584620676cdab5e11688134eae0edb9ac7f0349057dc1a7978458f6dc0d406353c5b79796d8e48e43f8',
  // AUTH_TOKEN_
};
