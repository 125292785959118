export enum AgreementType {
  PrivacyAgreement = 1,
  UseAgreement = 2,
  SaleAgreement = 3,
  RegistrationCard = 4,
  ConfirmationCard = 5,
  CookiePolicy = 6,
  KVKK = 7,
  BasketAgreement = 0,
  OnlineCheckin = 10,
  FAQContact = 11,
  Covid19 = 20,
  CallCenter = 21,
  SecurityandParticipationConditions = 22,
  Invoice = 23,
  CancellationAgreement = 24,
  AboutUs = 46,
}

export interface AgreementModel {
  AGREEMENT: string;
  HOTELID: number;
  NOTSHOWVOUCHER: boolean;
}
