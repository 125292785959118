<div class="form-group lead">
  <label class="form-title" (click)="goster()" *ngIf="inputLabel">{{
    inputLabel | translate | async
  }}</label>
  <span class="form-input-wrapper">
    <ng-container *ngIf="optionsText?.length > 0; else object">
      <select
        class="form-select"
        (change)="change($event.target.value)"
        [ngClass]="{
          'is-invalid': this.formService.hasError(
            this.isSubmit,
            this.formControl
          ),
          'is-valid':
            !this.formService.hasError(this.isSubmit, this.formControl) &&
            isSubmit
        }"
      >
        <option [value]="option" *ngFor="let option of optionsText">
          {{ option }}
        </option>
      </select>
    </ng-container>

    <div class="bar"></div>
    <div class="hint" *ngIf="hint">
      <i>{{ hint }}</i>
    </div>
  </span>
</div>
<ng-template #object>
  <select
    (change)="change($event.target.value)"
    class="form-select"
    [ngClass]="{
      'is-invalid': this.formService.hasError(this.isSubmit, this.formControl),
      'is-valid':
        !this.formService.hasError(this.isSubmit, this.formControl) && isSubmit
    }"
  >
    <option
      [value]="option[optionsObject.valueProperty]"
      *ngFor="let option of optionsObject.options"
    >
      {{ option[optionsObject.textProperty] }}
    </option>
  </select>
</ng-template>
