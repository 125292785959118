import { ApplicationRef, Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Subject, first, race, takeUntil, timer } from 'rxjs';
import { DialogAdvertisement } from 'src/app/dialog/components/example/dialog-advertisement/dialog-advertisement.component';
import { OnlinePosLoadingComponent } from 'src/app/dialog/components/example/online-pos-loading/online-pos-loading.component';
import { DialogDetailConfig } from 'src/app/dialog/model/DialogDetailConfig';
import { DialogService } from 'src/app/dialog/service/dialog.service';
import { Basket } from 'src/app/models/(basket)/Basket.model';
import { Settings } from 'src/app/models/(AllData)/Settings.model';
import { OnlinePos } from 'src/app/models/pos/OnlinePos.model';
import { BasketService } from '../app/basket.service';
import { CloseSellComponent } from 'src/app/dialog/components/example/close-sell/close-sell.component';
import { ExtraProduct } from 'src/app/models/(item)/extra-product/ExtraProduct';
import { CompanyInfo } from 'src/app/models/(AllData)/CompanyInfo.model';
import {
  Promotion,
  PromotionList,
  PromotionWithStatus,
} from 'src/app/models/(AllData)/Promotion.model';
import { Title } from '@angular/platform-browser';
import { Language } from 'src/app/models/TranslateLabels';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
export class Project {
  public FAVICON_URL: string;
  public TITLE: string;
  public API_URL: string;
  constructor(_init: Project) {
    this.FAVICON_URL = _init?.FAVICON_URL || environment.FAVICON_URL;
    this.TITLE = _init?.TITLE || environment.TITLE;
    this.API_URL = _init?.API_URL || environment.API_URL;
    if (!this.API_URL.endsWith('/')) {
      this.API_URL = this.API_URL + '/';
    }
  }
}
interface allowedPromotionBank {
  PAYMENTGATEID: any;
  PAYMENTGATENAME: any;
}
@Injectable({
  providedIn: 'root',
})
export class AppService {
  project$: BehaviorSubject<Project> = new BehaviorSubject(null);

  progress = new BehaviorSubject<boolean>(true);

  settings$: BehaviorSubject<Settings> = new BehaviorSubject<Settings>(null);

  companyInfo$: BehaviorSubject<CompanyInfo> = new BehaviorSubject<CompanyInfo>(
    null,
  );

  hotelId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  language$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  languages$: BehaviorSubject<Language[]> = new BehaviorSubject<Language[]>(
    null,
  );

  sendGTMEvents$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  sendGAEvents$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // promotions$: BehaviorSubject<Promotion[]> = new BehaviorSubject<Promotion[]>(
  //   null
  // ); // promosyonlar
  promotionList$: BehaviorSubject<PromotionList[]> = new BehaviorSubject<
    PromotionList[]
  >(null);
  allowedPromotionBank$: BehaviorSubject<allowedPromotionBank> =
    new BehaviorSubject<allowedPromotionBank>(null);
  extraProducts$: BehaviorSubject<ExtraProduct[]> = new BehaviorSubject(null); // extra ürünler

  isShowExtraServices: boolean = false;

  isLocal$: BehaviorSubject<boolean> = new BehaviorSubject(null); // local mi yoksa online mı

  exchangeRate$: BehaviorSubject<number> = new BehaviorSubject(1); // döviz kuru

  initiliazeExchangeRate$: BehaviorSubject<boolean> = new BehaviorSubject(
    false,
  );

  saveBasketUID$: BehaviorSubject<string> = new BehaviorSubject(null); // sepetin kaydedildikten sonra dönen uid'si

  onlinePosData$: BehaviorSubject<OnlinePos> = new BehaviorSubject(null); // online pos data

  constructor(
    protected dialogService: DialogService,
    private titleService: Title,
    private appRef: ApplicationRef,
    private http: HttpClient,
  ) {}

  progressBool(progress?: boolean) {
    if (progress) {
      this.progress.next(progress);
    } else {
      this.progress.next(!this.progress.value);
    }
  }
  isLocalFiiling(local: boolean) {
    this.isLocal$.next(local);
  }
  exchangeRateFiiling(exchangeRate: number) {
    this.exchangeRate$.next(exchangeRate);
  }
  timeStampToDate(timestamp) {
    if (timestamp?.seconds) {
      let date = new Date(timestamp.seconds * 1000);
      moment.locale(this.language$.value);
      return moment(date).format('LL');
    } else {
      return null;
    }
  }

  momentFormatDDMMYYYY(date) {
    return moment(date).format('DD/MM/YYYY');
  }
  private destroy$ = new Subject<void>();
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  async openOnlinePosDialog(onlinePosData: any) {
    const dialogDetailConfig: DialogDetailConfig = {
      headerShow: true,
      backgroundShow: true,
      buttonShow: true,
      descriptionShow: true,
    };

    const ref = this.dialogService.open(
      OnlinePosLoadingComponent,
      {
        data: { message: 'Dynamic dialog opened by DialogService' },
      },
      dialogDetailConfig,
      onlinePosData,
    );

    ref.afterClosed.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      console.log('Dialog closed');
    });
    this.progressBool();
  }

  openBuyLaterDialog() {
    const dialogDetailConfig: DialogDetailConfig = {
      headerShow: true,
      backgroundShow: false,
      buttonShow: true,
      descriptionShow: true,
    };
    let basketBuyLater: Basket = JSON.parse(
      localStorage.getItem('basket-buy-later'),
    );
    localStorage.removeItem('basket-buy-later');

    const dialogDetail = {
      header: 'Yarım Kalan Rezervasyon',
      // backgroundUrl: null,
      button: {
        title: 'Rezervasyonu Tamamla',
        url: 'basket',
      },
      data: basketBuyLater,
    };

    const ref = this.dialogService.open(
      DialogAdvertisement,
      {
        data: { message: 'Dynamic dialog opened by DialogService' },
      },
      dialogDetailConfig,
      dialogDetail,
    );

    ref.afterClosed.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      console.log('Dialog closed');
    });
    // this.progressBool();
  }

  closeSellDialog() {
    const dialogDetailConfig: DialogDetailConfig = {
      headerShow: true,
      backgroundShow: false,
      buttonShow: false,
      descriptionShow: true,
    };
    const dialogDetail = {
      phoneNumber: '0532 000 00 00',
    };

    const ref = this.dialogService.open(
      CloseSellComponent,
      {
        data: { message: 'Dynamic dialog opened by DialogService' },
      },
      dialogDetailConfig,
      dialogDetail,
    );

    ref.afterClosed.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      console.log('Dialog closed');
    });
  }
  favIconChange(favIcon?: string) {
    if (favIcon || this.companyInfo$.value?.LOGOURL_FAVICON) {
      let iconUrl = favIcon || this.companyInfo$.value?.LOGOURL_FAVICON;
      // https://github.githubassets.com/favicons/favicon.svg
      let faviconMobil = document.querySelector('#faviconMobil');
      faviconMobil['href'] = iconUrl;
      let faviconTablet = document.querySelector('#faviconTablet');
      faviconTablet['href'] = iconUrl;
      let faviconDesktop = document.querySelector('#faviconDesktop');
      faviconDesktop['href'] = iconUrl;
      let faviconDesktop2 = document.querySelector('#faviconDesktop2');
      faviconDesktop2['href'] = iconUrl;
      // document.getElementsByTagName('head')[0].appendChild(link);
    }
  }

  setWebsite(
    settings: Settings,
    companyInfo: CompanyInfo,
    languages: Language[],
  ) {
    let _settings = new Settings(settings);
    this.settings$.next(_settings);
    this.colorPalet(_settings.PRIMARYCOLOR, _settings.SECONDARYCOLOR);
    this.companyInfo$.next(companyInfo);
    localStorage.setItem('companyInfo', JSON.stringify(companyInfo));
    localStorage.setItem('settings', JSON.stringify(settings));
    this.titleService.setTitle(this.companyInfo$.value.NAME);
    this.addPlugins(
      companyInfo.GANALYTICSCODE || null,
      companyInfo.GOOGLETAGMANAGERCODE || null,
    );

    this.favIconChange();
    this.setWebsiteLang(languages);
  }
  colorPalet(primaryColor, secondaryColor) {
    const htmlElStyle = document.documentElement.style;
    htmlElStyle.setProperty('--primaryColor', primaryColor);
    htmlElStyle.setProperty('--secondaryColor', secondaryColor);
  }

  setWebsiteLang(languages: Language[]) {
    if (this.hotelId$.value == 28818) {
      this.language$.next('TR');
      this.languages$.next([{ LANGUAGECODE: 'TR', LANGUAGENAME: 'Türkçe' }]);
      return;
    }

    if (languages?.length > 0) {
      localStorage.setItem('languages', JSON.stringify(languages));

      let windowLang =
        localStorage.getItem('lang') ||
        window.navigator.language.split('-')[0].toLocaleUpperCase() ||
        'TR';

      languages = languages.filter((x) => {
        return ['TR', 'EN', 'DE', 'RU'].includes(x.LANGUAGECODE);
      });

      if (!this.language$.value) {
        if (languages.find((x) => x.LANGUAGECODE == windowLang)) {
          if (this.language$.value != windowLang)
            this.language$.next(windowLang);
          localStorage.setItem('lang', windowLang);
        } else {
          if (this.language$.value != 'TR') {
            windowLang = 'TR';
            this.language$.next('TR');
            localStorage.setItem('lang', 'TR');
          }
        }
      }
      //option sıkıntı cıkarıyordu
      let choose = languages.find((x) => x.LANGUAGECODE == windowLang);
      languages = languages.filter((x) => x.LANGUAGECODE != windowLang);
      languages.unshift(choose);
      if (languages.length > 1) {
        this.languages$.next(languages);
      }
    } else {
      this.setLang('TR');
    }
  }
  setLang(lang: string) {
    console.log('lang', lang);
    lang = lang.toUpperCase();
    if (this.language$.value != lang) {
      this.language$.next(lang);
      localStorage.setItem('lang', lang);
    }
  }

  createScript({
    src,
    content,
    sync = false,
  }: {
    src?: string;
    content?: string;
    sync?: boolean;
  }): Promise<any> {
    const promise = new Promise<void>((resolve, reject) => {
      if (document.querySelector(`[data-btoaid="${btoa(src)}"]`)) {
        resolve();
        return;
      }

      race(this.appRef.isStable.pipe(first((x) => !!x)), timer(2000)).subscribe(
        () => {
          const script = document.createElement('script');
          const tag = document.getElementsByTagName('head')[0];
          script.async = true;
          if (src) {
            script.src = src;
          }
          if (content) {
            script.innerHTML = content;
          }
          script.type = 'text/javascript';
          script.onload = () => {
            script.setAttribute('data-btoaid', btoa(src));
            resolve();
          };
          script.onerror = () => {
            script.remove();
            reject();
          };
          // this.insertScripts(script.outerHTML, false);
          tag.appendChild(script);
          // tag.parentNode.insertBefore(script, tag);
        },
      );
    });
    if (sync) {
      return promise;
    }
    return Promise.resolve();
  }
  insertScripts(script, position: number): void {
    try {
      const element = document.createRange().createContextualFragment(script);
      if (position == 2) {
        const footer = document.getElementsByTagName('footer');
        footer.item(0).appendChild(element);
        // footer.item.replaceChild(scriptCopy,script);
      } else if (position == 1) {
        document.head.appendChild(element);
      } else if (position == 3) {
        document.body.appendChild(element);
      }
    } catch (error) {}
  }
  extensionScripts(scripts) {
    if (!scripts || scripts?.length == 0) return;

    const scptHead = scripts.filter((x) => x.ADDEDPOSITION == 1);
    const scptFooter = scripts.filter((x) => x.ADDEDPOSITION == 2);
    const scptBody = scripts.filter((x) => x.ADDEDPOSITION == 3);

    if (scptBody && scptBody.length > 0) {
      scptBody.map((x) => {
        this.insertScripts(x.RUNSCRIPT, 3);
      });
    }

    if (scptHead && scptHead.length > 0) {
      scptHead.map((x) => {
        this.insertScripts(x.RUNSCRIPT, 1);
      });
    }

    if (scptFooter && scptFooter.length > 0) {
      scptFooter.map((x) => {
        this.insertScripts(x.RUNSCRIPT, 2);
      });
    }
  }
  addPlugins(GAUID?: string, GTMUID?: string) {
    if (GAUID && !this.sendGAEvents$.value) {
      const i = window;
      const ga = 'ga';
      i['GoogleAnalyticsObject'] = ga;
      i[ga] =
        i[ga] ||
        function () {
          (i[ga].q = i[ga].q || []).push(arguments);
        };
      // @ts-ignore
      i[ga].l = 1 * new Date();
      this.createScript({
        src: 'https://www.googletagmanager.com/gtag/js?id=' + GAUID,
      });
      this.insertScripts(
        `
      <script>
               window.dataLayer = window.dataLayer || [];
               function gtag(){dataLayer.push(arguments);}
               gtag('js', new Date());
               gtag('config', '${GAUID}');
      </script>
      `,
        1,
      );

      // this.insertScripts(
      //   scptHead.map((x) => x.RUNSCRIPT),
      //   false
      // );
      this.sendGAEvents$.next(true);
      // window['ga']('create', GAUID, 'auto');
    }

    if (GTMUID && !this.sendGTMEvents$.value) {
      window['dataLayer'] = window['dataLayer'] || [];

      window['dataLayer'].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });

      window['my_gtag'] = function () {
        window['dataLayer'].push(arguments);
      };

      window['my_gtag']('js', new Date());

      this.createScript({
        src: 'https://www.googletagmanager.com/gtag/js?id=' + GTMUID,
      });
      this.insertScripts(
        `
      <script>
               window.dataLayer = window.dataLayer || [];
               function gtag(){dataLayer.push(arguments);}
               gtag('js', new Date());
               gtag('config', '${GTMUID}');
      </script>
      `,
        1,
      );

      this.sendGTMEvents$.next(true);
    }
  }

  async setProject() {
    let init = () => {
      let project = this.project$.value;
      this.favIconChange(project.FAVICON_URL);
      this.titleService.setTitle(project.TITLE);
    };
    if (localStorage.getItem('project')) {
      this.project$.next(
        new Project(JSON.parse(localStorage.getItem('project'))),
      );
      init();
      return;
    }
    await this.http
      .get('/assets/endpoint.json')
      .toPromise()
      .then((resp) => {
        this.project$.next(new Project(resp as any));
      })
      .catch((error) => {
        console.log('assets cant get ', error);
        this.project$.next(new Project(null));
      })
      .finally(() => {
        localStorage.setItem('project', JSON.stringify(this.project$.value));
        init();
      });
  }
}
