import { AppService } from 'src/app/services/core/app.service';
import { Component, OnInit } from '@angular/core';
import { BasketService } from 'src/app/services/app/basket.service';

@Component({
  selector: 'basket-preview-main',
  templateUrl: './basket-preview-main.component.html',
  styleUrls: ['./basket-preview-main.component.css'],
})
export class BasketPreviewMainComponent implements OnInit {
  ISSHOWSELECTTICKETDATE: boolean = true;
  constructor(
    public basketService: BasketService,
    private appService: AppService
  ) {
    this.ISSHOWSELECTTICKETDATE =
      this.appService.settings$.value.ISSHOWSELECTTICKETDATE;
  }

  ngOnInit() {}
  goster() {}
}
