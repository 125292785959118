import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { POS_SAVECHECK_Request } from 'src/app/models/pos/SP_POS_SAVECHECK_Request.model';
import { RequestGetTicket } from 'src/app/models/RequestGetTicket.model';
import { environment } from 'src/environments/environment';
import { SavecheckResponse } from 'src/app/models/pos/SP_POS_SAVECHECK_Response.model';
import { ConfigService } from 'src/app/initializer/config.service';
import { AppService } from './app.service';
export class ConfigBaseRequest {
  Action: string;
  BaseObject?: string;

  [k: string]: any;
}

export class GetConfigRequest extends ConfigBaseRequest {
  ConfigName: string;

  constructor(ConfigName) {
    super();
    this.Action = 'GetConfig';
    this.ConfigName = ConfigName;
  }
}
export class ApiResponse {
  DataTypes: Array<ApiDataTypeObject>;
  ResultSets: Array<any>;
}
interface SelectResponse {
  DataTypes: null;
  TotalCount: null;
  ResultSets: [
    [
      {
        TRANSACTIONSTATUS: number;
        ID: number;
        COSTPRICE: number;
        COSTCURRENCYCODE: string;
        FIRSTNAME: string;
        LASTNAME: string;
      },
    ],
  ];
  SQL: string;
}

export interface BaseRequest<T> {
  Action: string;
  Object: string;
  Parameters: T;
}
export interface GetAggrement {
  HOTELGROUPID?: string;
  HOTELID?: number;
  LANGUAGECODE?: string;
  PORTALID?: number;
  TYPEID?: number;
}
export interface ApiDataTypeObject {
  HasDefault: boolean;
  IsNullable: boolean;
  Name: string;
  Type: string;
  IsComputed?: boolean;
  IsIdentity?: boolean;
  IsPrimaryKey?: boolean;
}
export class SaveContactRequest {
  HOTELID: number;
  PORTALID: number;
  EMAIL: string;
  PHONE: string;
  NOTES: string;
  ISPAYMENT: boolean;
  RESERVATIONREQUEST: string;
}

class RequestExchangeRate {
  public Action: string;
  public Object: string;
  public Parameters: {
    DATE: Date;
    TARGETCURRENCYID: number;
    BASECURRENCYID: number;
  };
}
class RequestFinishBasket {
  public Action: string;
  public Object: string;
  public Parameters: {
    DATA: string;
    DATA2?: string;
    DATA3?: string;
    CLOSECHECK: number;
    PRINTCHECK: number;
  };
}
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private appService: AppService,
  ) {}

  getExchangeRate(FOREIGNCURRENCYID: number) {
    if (!this.appService.initiliazeExchangeRate$.value) {
      this.appService.initiliazeExchangeRate$.next(true);
    }
    let request: RequestExchangeRate = {
      Action: 'Function',
      Object: 'FN_HOTEL_EXCHANGERATES',
      Parameters: {
        DATE: new Date(),
        TARGETCURRENCYID: 142,
        BASECURRENCYID: FOREIGNCURRENCYID,
      },
    };

    return this.postRequest(request);
  }

  async getData(TICKETDATE: string) {
    let request = new RequestGetTicket(
      'Function',
      'FN_POS_PARK_GETONLINEDATA',
      {
        HOTELID: this.configService.hotelId,
        TICKETDATE: TICKETDATE,
        LANG: this.appService.language$.value,
      },
    );

    return this.postRequest(request).toPromise();
  }

  async getConcertData(TICKETDATE: string, promise?: boolean) {
    let request = new RequestGetTicket(
      'Function',
      'FN_POS_PARK_GETONLINEDATA2',
      { HOTELID: this.configService.hotelId, TICKETDATE: TICKETDATE },
    );
    if (promise) {
      return this.postRequestPromise(request);
    } else return this.postRequest(request);
  }
  async getSettings() {
    let request: BaseRequest<{ HOTELID: number }> = {
      Action: 'Function',
      Object: 'FN_POS_PARK_GETONLINESETTING',
      Parameters: { HOTELID: this.configService.hotelId },
    };

    return this.postRequest(request).toPromise();
  }
  basketSave(
    basketItem: POS_SAVECHECK_Request[],
  ): Observable<SavecheckResponse> {
    let request: RequestFinishBasket = {
      Action: 'Execute',
      Object: 'SP_POS_PARK_ONLINETICKETSAVE',
      Parameters: {
        DATA: JSON.stringify(basketItem[0].DATA),
        CLOSECHECK: basketItem[0].CLOSECHECK,
        PRINTCHECK: basketItem[0].PRINTCHECK,
      },
    };
    if (basketItem?.[1])
      request.Parameters.DATA2 = JSON.stringify(basketItem[1].DATA);
    if (basketItem?.[2])
      request.Parameters.DATA3 = JSON.stringify(basketItem[1].DATA);
    return this.postRequest(request);
  }
  transactionRequest(uid: string): Observable<SelectResponse> {
    let request: any = {
      Action: 'Select',
      Object: 'QB_HOTEL_TRANSACTION',
      Select: [
        'TRANSACTIONSTATUS',
        'ID',
        'COSTPRICE',
        'COSTCURRENCYCODE',
        'FIRSTNAME',
        'LASTNAME',
      ],
      Where: [
        {
          Column: 'PORTALID',
          Value: '1',
          Operator: '=',
        },
        {
          Column: 'HOTELID',
          Value: this.configService.hotelId,
          Operator: '=',
        },
        {
          Column: 'BASKETPROFILEUNIQUEID',
          Value: uid,
          Operator: '=',
        },
      ],
      OrderBy: [
        {
          Column: 'TRANSACTIONSTATUS',
          Direction: 'DESC',
        },
      ],
      Paging: {
        Current: 1,
        ItemsPerPage: 1,
      },
      DataTypes: false,
      TotalCount: false,
    };
    return this.postRequest(request);
  }
  findBasketItem(uid: string): Observable<any> {
    let request: BaseRequest<any> = {
      Action: 'Execute',
      Object: 'SP_HOTELBOOKING_FIND_CONTACTREQUEST',
      Parameters: {
        UID: uid,
        HOTELID: this.configService.hotelId,
      },
    };
    return this.postRequest(request);
  }
  getAggrement(agrrementType: number): Observable<any> {
    const req: BaseRequest<GetAggrement> = {
      Action: 'Execute',
      Object: 'SP_PORTALV4_GETAGREEMENT',
      Parameters: {
        HOTELGROUPID: null,
        HOTELID: this.configService.hotelId,
        LANGUAGECODE: this.appService.language$.value || 'TR',
        PORTALID: 1,
        TYPEID: agrrementType,
      },
    };
    return this.postRequest(req);
  }

  updateBasket(uid: string, data: any): Promise<any> {
    let request: BaseRequest<{ REQUESTUID; NOTES; RESERVATIONREQUEST }> = {
      Action: 'Execute',
      Object: 'SP_UPDATECONTACTREQUEST',
      Parameters: {
        REQUESTUID: uid,
        NOTES: this.appService.language$.value || 'TR',
        RESERVATIONREQUEST: data,
      },
    };
    return this.postRequest(request).toPromise();
  }
  postRequest(request: BaseRequest<any> | ConfigBaseRequest): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${
      //   environment['AUTH_TOKEN_' + this.configService.hotelId]
      // }`,
    });
    try {
      if (!request.Parameters?.HOTELID)
        request.Parameters = {
          ...request.Parameters,
          HOTELID: this.configService.hotelId,
        };
    } catch (error) {}
    // headers.append('disableRetryOnError', '0');
    //}

    try {
      return this.http.post<ApiResponse | string | any>(
        this.appService.project$.value.API_URL +
          request.Action +
          '/' +
          (request.Object ||
            request?.['ConfigName'] ||
            this.configService.hotelId),
        JSON.stringify(request),
        {
          headers,
        },
      );
    } catch (error) {
      return error;
    }
  }

  selectRequest(request: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${
      //   environment['AUTH_TOKEN_' + this.configService.hotelId]
      // }`,
    });
    try {
      if (!request.Parameters?.HOTELID)
        request.Parameters = {
          ...request.Parameters,
          HOTELID: this.configService.hotelId,
        };
    } catch (error) {}
    // headers.append('disableRetryOnError', '0');
    //}
    try {
      return this.http.post<ApiResponse | string | any>(
        this.appService.project$.value.API_URL +
          request.Action +
          '/' +
          request.Object,
        JSON.stringify(request),
        {
          headers,
        },
      );
    } catch (error) {
      return error;
    }
  }
  postRequestInsertHotelId(request: BaseRequest<any>): Observable<any> {
    request.Parameters.HOTELID = this.configService.hotelId;
    return this.postRequest(request);
  }
  execPromotionCode(
    productId: Number,
    promotionCode: string,
    binNumber?: string,
  ): Observable<any> {
    let request: BaseRequest<{
      HOTELID;
      PROMOTIONCODE;
      PRODUCTID;
      BINNUMBER?: string;
    }> = {
      Action: 'Execute',
      Object: 'SP_POS_PARK_CHECK_PROMOTIONCODE_ISVALID',
      Parameters: {
        HOTELID: '28818',
        PROMOTIONCODE: promotionCode,
        PRODUCTID: productId, //1014162 ---1014162,1014164
      },
    };
    if (binNumber) {
      request.Parameters.BINNUMBER = binNumber;
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${
      //   environment['AUTH_TOKEN_' + this.configService.hotelId]
      // }`,
    });
    try {
      if (!request.Parameters?.HOTELID)
        request.Parameters = {
          ...request.Parameters,
          HOTELID: this.configService.hotelId,
        };
    } catch (error) {}
    // headers.append('disableRetryOnError', '0');
    //}
    try {
      return this.http.post<ApiResponse | string | any>(
        this.appService.project$.value.API_URL +
          request.Action +
          '/' +
          request.Object,
        JSON.stringify(request),
        {
          headers,
        },
      );
    } catch (error) {
      return error;
    }
  }
  postRequestPromise(request: BaseRequest<any>): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${
      //   environment['AUTH_TOKEN_' + this.configService.hotelId]
      // }`,
    });
    try {
      if (!request.Parameters?.HOTELID)
        request.Parameters = {
          ...request.Parameters,
          HOTELID: this.configService.hotelId,
        };
    } catch (error) {}
    // headers.append('disableRetryOnError', '0');
    //}

    return this.http
      .post<any>(
        this.appService.project$.value.API_URL +
          request.Action +
          '/' +
          request.Object,
        JSON.stringify(request),
        {
          headers,
        },
      )
      .toPromise();
  }
  async getConfig(configName: string): Promise<{}> {
    const reqBody = new GetConfigRequest(configName);
    try {
      return await this.postRequest(reqBody).toPromise();
    } catch (e) {
      if (e.status === 404) {
        console.warn('Get Config 404', e);
      } else if (e.status === 200) {
        console.error('Get Config Error (with status code 200)', e);
      } else {
        console.warn('Get Config Error', e);
      }
      throw e;
    }
  }
}
