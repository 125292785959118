import { NgModule } from '@angular/core';
import { NavbarComponent } from './navbar.component';
import { TooltipDirective } from 'src/app/directives/tooltip.directive';
import { RouterModule } from '@angular/router';
import { BasketPreviewMainModule } from '../../(basket)/basket-preview/basket-preview-main/basket-preview-main.module';
import { ImportModule } from 'src/app/import.module';
import { BasicOptionModule } from '../../(shareds)/basic-option/basic-option.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipeModule } from 'src/app/pipes/translate.module';

@NgModule({
  imports: [
    ImportModule,
    RouterModule,
    BasketPreviewMainModule,
    BasicOptionModule,
    FormsModule,
    ReactiveFormsModule,
    TranslatePipeModule,
  ],
  declarations: [NavbarComponent, TooltipDirective],
  exports: [NavbarComponent],
})
export class NavbarModule {}
