import { Injectable } from '@angular/core';
import { ApiService } from '../core/api.service';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/models/(auth)/User.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  loginStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(private apiService: ApiService, private router: Router) {}

  RegisterRequest(
    sendSms: boolean,
    phone?: string,
    email?: string,
    nameAndSurname?: string,
    secret?: string
  ) {
    let request = {
      Parameters: {
        HOTELID: 28818,
        FULLNAME: nameAndSurname || '',
        PHONE: phone || '',
        EMAIL: email || '',
        SENDSMS: sendSms,
        PORTALID: 1,
      },
      Action: 'Execute',
      Object: 'SP_HOTELGUEST_REGISTERPOSCARDSTEP1',
    };
    if (secret) {
      request.Parameters['SECRET'] = secret;
    }
    return this.apiService.postRequest(request).toPromise();
  }
  LoginRequest(UID?: string, SECRET?: string) {
    let request = {
      Parameters: {
        HOTELID: 28818,
        SECRET: SECRET || '',
        UID: UID || '',
        PORTALID: 1,
      },
      Action: 'Execute',
      Object: 'SP_HOTELGUEST_REGISTERPOSCARDSTEP2',
    };
    return this.apiService.postRequest(request).toPromise();
  }

  userLogin(user: User) {
    this.loginStatus$.next(true);
    this.user$.next(user);

    localStorage.setItem('user', JSON.stringify(user));
    // this.router.navigate(['/concert']);
  }
  userLogout() {
    this.loginStatus$.next(false);
    this.user$.next(null);
    localStorage.removeItem('user');
    this.router.navigate(['/login']);
  }
  userStorageLogin(user: User) {}
}
