import { NgModule } from '@angular/core';
import { ReservationItemComponent } from './reservation-item.component';
import { ImportModule } from 'src/app/import.module';

@NgModule({
  imports: [ImportModule],
  declarations: [ReservationItemComponent],
  exports: [ReservationItemComponent],
})
export class ReservationItemModule {}
