import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasketPreviewItemComponent } from './basket-preview-item.component';

@NgModule({
  imports: [CommonModule],
  declarations: [BasketPreviewItemComponent],
  exports: [BasketPreviewItemComponent],
})
export class BasketPreviewItemModule {}
