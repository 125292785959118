import { NgModule } from '@angular/core';
import { ImportModule } from 'src/app/import.module';
import { LoadingModule } from '../loading/loading.module';
import { FooterComponent } from './footer.component';
import { TranslatePipeModule } from 'src/app/pipes/translate.module';

@NgModule({
  imports: [ImportModule, LoadingModule, TranslatePipeModule],
  declarations: [FooterComponent],
  exports: [FooterComponent],
})
export class FooterModule {}
