import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor() {}
  hasError(isSubmit: boolean, formControl: FormControl): boolean {
    return (isSubmit && formControl?.invalid) == true ? true : false;
  }

  // error
  getFormValidationErrors(formControl: FormControl): string[] {
    let messages: string[] = [];

    Object.keys(formControl.errors).forEach((error) => {
      messages.push(this.getValidationErrors(error, formControl.errors[error]));
    });

    return messages;
  }
  // özelleştirilmiş mesajlar
  getValidationErrors(error: any, value: string) {
    let messages: string;
    switch (error) {
      case 'required':
        messages = `Bu bilginin doldurulması zorunludur.`;
        break;
      case 'minlength':
        messages = `  En az ${value?.['requiredLength']} adet  karakter girilmelidir. `;
        break;
      case 'maxlength':
        messages = `  En fazla ${value?.['requiredLength']} adet  karakter girilmelidir. `;
        break;
      case 'pattern':
        if (value?.['requiredPattern'] == '^[0-9]*$')
          messages = `Sadece sayı girilmelidir.`;
        else if (value?.['requiredPattern'] == '^[a-zA-Z]*$')
          messages = `Sadece harf girilmelidir.`;
        break;
      case 'min':
        messages = `Minimum değerin altında kaldı.`;

        break;
      case 'max':
        messages = `Maksimum değerin üstünde kaldı.`;
        break;
      case 'email':
        messages = `Hatalı mail formatı girildi.`;
        break;
      case 'validatePhoneNumber':
        messages = `Hatalı numara girildi.`;
        break;
      case 'wrongTcNumber':
        messages = `Hatalı tc kimlik numarası girildi.`;
        break;
    }
    return messages;
  }
}
