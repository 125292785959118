import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from './translate.service';
@Injectable({
  providedIn: 'root',
})
export class ToastrServiceCore {
  constructor(
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {}

  success(message: string, title: string = 'Info', args?: Array<object>) {
    if (title == null) {
      title = 'Info';
    }
    message = this.translateService.transform(message, args);
    title = this.translateService.transform(title, args);
    this.toastrService.success(message, title);
  }

  error(message: string, title: string = 'Info', args?: Array<object>) {
    if (title == null) {
      title = 'Info';
    }
    message = this.translateService.transform(message, args);
    title = this.translateService.transform(title, args);
    this.toastrService.error(message, title);
  }

  warning(message: string, title: string = 'Info', args?: Array<object>) {
    if (title == null) {
      title = 'Info';
    }
    message = this.translateService.transform(message, args);
    title = this.translateService.transform(title, args);
    this.toastrService.warning(message, title);
  }
  info(message: string, title: string = 'Info', args?: Array<object>) {
    if (title == null) {
      title = 'Info';
    }
    message = this.translateService.transform(message, args);
    title = this.translateService.transform(title, args);
    this.toastrService.info(message, title);
  }
}
