<div class="basket-sidebar-item-main row">
  <div class="remove-icon">
    <i class="fa-solid fa-trash"></i>
  </div>
  <div class="basket-sidebar-item-header col-md-2">
    <span
      ><img
        src="{{
          basketItem.TICKETURL ??
            '../../../../../../assets/placeholder-image.jpeg'
        }}"
        style="height: 75px; width: 100%"
    /></span>
  </div>
  <div class="basket-sidebar-item-quantity col-md-6" style="padding-left: 5px">
    <div class="title row">
      <span class="basket-sidebar-item-title">{{ basketItem.TICKETNAME }}</span>
    </div>
    <div class="count">
      <span
        *ngIf="basketItem.BASKETDETAIL?.ADULTCOUNT"
        style="padding-right: 3px"
      >
        {{ basketItem.BASKETDETAIL?.ADULTCOUNT }} Yetişkin
      </span>
      <span
        *ngIf="
          basketItem.BASKETDETAIL?.ADULTCOUNT &&
          basketItem.BASKETDETAIL?.CHILDRENCOUNT
        "
      >
        /
      </span>
      <span *ngIf="basketItem.BASKETDETAIL?.CHILDRENCOUNT">
        {{ basketItem.BASKETDETAIL?.CHILDRENCOUNT }}
        {{ "Children" | translate | async }}
      </span>
    </div>
  </div>

  <div class="basket-sidebar-item-total col-md-2" style="font-weight: bold">
    <span
      >{{ basketItem.BASKETDETAIL?.TOTALPRICE }} {{ basketItem.CURRENCY }}</span
    >
  </div>
</div>
