import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { AppService } from '../services/core/app.service';

@Injectable({
  providedIn: 'root',
})
export class ConcertGroupedGuard implements CanActivate {
  constructor(public router: Router, private appService: AppService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.init(next);
  }

  async init(next: ActivatedRouteSnapshot) {
    await this.appService.settings$.pipe(first((x) => x != null)).toPromise();

    if (!this.appService.settings$.value.ISACTIVECONCERTGROUPED) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
