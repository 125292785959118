<div class="dialog-main lead dialog-glass-card'">
  <div class="row dialog-body-main">
    <div class="col-md-12 body">
      <div
        class="row header-main"
        *ngIf="dialogService.dialogDetail?.value?.header"
      >
        <div class="col-10">
          <div class="header">
            <div class="header-title" (click)="goster()">
              {{ dialogService.dialogDetail?.value?.header }}
            </div>
          </div>
        </div>
        <div class="col-2 close-button" (click)="onClose()">
          <i class="fa-solid fa-xmark close-button-icon"></i>
        </div>
      </div>
      <div class="row icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 512 512"
          style="height: 70px; fill: #fff; margin: auto"
        >
          <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
          />
        </svg>
      </div>
      <div
        class="row description-main"
        *ngIf="dialogService.dialogDetail?.value?.description"
      >
        <div class="col-md-12">
          <div class="row">
            <div class="description col-md-12">
              {{ dialogService.dialogDetail?.value?.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
