<div
  class="dialog-main lead"
  [ngClass]="
    dialogService.dialogDetailConfig?.value?.backgroundShow
      ? 'dialog-image'
      : 'dialog-glass-card'
  "
>
  <div class="row dialog-body-main">
    <div class="col-md-12 body">
      <div
        class="row header-main"
        *ngIf="dialogService.dialogDetailConfig?.value?.headerShow"
      >
        <div class="col-md-10">
          <div class="header">
            <div class="header-title" (click)="goster()">
              {{ dialogService.dialogDetail?.value?.header }}
            </div>
          </div>
        </div>
        <div class="col-md-2 close-button" (click)="onClose()">
          <i class="fa-solid fa-xmark close-button-icon"></i>
        </div>
      </div>
      <div
        class="row description-main"
        *ngIf="
          dialogService.dialogDetailConfig?.value?.descriptionShow &&
          dialogService.dialogDetail?.value?.data as basket
        "
      >
        <div class="col-md-12">
          <div class="row">
            <div
              class="description col-md-12"
              *ngFor="let basketItem of basket.ITEMS.TICKETITEMS"
            >
              <reservation-item [basketItem]="basketItem"></reservation-item>

              <!-- {{ dialogService.dialogDetail?.value?.description.data }} -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-md-12 footer"
      *ngIf="dialogService.dialogDetailConfig?.value?.buttonShow"
    >
      <div class="row button-main">
        <div class="row" style="padding: 0px">
          <div class="button-div" style="text-align: right">
            <a (click)="onSubmit()">
              <button class="button-30">
                {{ dialogService.dialogDetail?.value?.button?.title }}
              </button></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
