<div class="c" (click)="isShowFunc($event)">
  <input
    type="checkbox"
    [id]="'faq-' + type + (index + 1)"
    disabled
    [checked]="isShow ? 'checked' : ''"
  />
  <h1>
    <label [for]="'faq-' + type + (index + 1)">
      <span>
        {{ basketItemDetail?.NAME }}
        <i
          class="basket-item-delete-icon fa-solid fa-x"
          (click)="deleteThisItem()"
        ></i>
      </span>
    </label>
  </h1>
  <div class="p">
    <div class="ticket-basket-container">
      <div class="ticket-basket-properties">
        <div class="ticket-basket-panel-date">
          <ng-container *ngIf="!isShowImage; else ImageTemplate">
            <div class="ticket-basket-panel-date-day">
              {{ basketItemDetail.ticketDate_date }}
            </div>
            <div class="ticket-basket-panel-date-month">
              <div class="ticket-basket-panel-date-day-str">
                {{ basketItemDetail.ticketDate_dddd }}
              </div>
              <div class="ticket-basket-panel-date-month-str">
                {{ basketItemDetail.ticketDate_MMM }}
              </div>
              <div class="ticket-basket-panel-date-month-str">
                {{ basketItemDetail.ticketDate_YYYY }}
              </div>
            </div>
          </ng-container>
          <ng-template #ImageTemplate>
            <div class="ticket-basket-panel-date-image">
              <img
                [src]="basketItemDetail?.IMAGE"
                style="width: 100%; height: 100%; object-fit: cover"
              />
            </div>
          </ng-template>
        </div>
        <div class="ticket-basket-panel-item">
          <div class="ticket-basket-panel-item-info">
            <div class="ticket-basket-panel-item-name">
              {{ basketItemDetail?.NAME }}
            </div>
            <div class="ticket-basket-panel-item-pax">
              {{ basketItemDetail.SUBTEXT }}
            </div>
            <div
              class="ticket-basket-panel-item-pax"
              *ngIf="basketItemDetail?.OPTIONALTEXT"
            >
              {{ basketItemDetail.OPTIONALTEXT }}
            </div>
            <div class="ticket-basket-panel-item-price">
              <div *ngIf="!basketItemDetail?.['PROMOTION']">
                {{ basketItemDetail.TOTALPRICE }}
                {{ basketItemDetail.CURRENCY }}
              </div>
              <div
                class="ticket-basket-panel-item-price-discount-wrapper"
                *ngIf="basketItemDetail?.['PROMOTION']"
              >
                <div class="ticket-basket-panel-item-price-left">
                  <div class="ticket-basket-panel-item-price-discount-price">
                    {{ basketItemDetail.TOTALPRICE }}
                    {{ basketItemDetail.CURRENCY }}
                  </div>
                  <div
                    class="ticket-basket-panel-item-price-discount-before-price"
                  >
                    {{ basketItemDetail.PROMOTION.PROMOTIONBEFOREPRICE }}
                    {{ basketItemDetail.CURRENCY }}
                  </div>
                </div>

                <div
                  class="ticket-basket-panel-item-price-right ticket-basket-panel-item-price-discount-detail"
                >
                  % {{ basketItemDetail.PROMOTION.PROMOTIONAMOUNT }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
