import { NgModule } from '@angular/core';
import { ImportModule } from 'src/app/import.module';
import { FooterModule } from '../../(shareds)/footer/footer.module';
import { LoadingModule } from '../../(shareds)/loading/loading.module';
import { NavbarModule } from '../navbar/navbar.module';
import { HomeComponent } from './home.component';

@NgModule({
  declarations: [HomeComponent],
  exports: [],
  imports: [ImportModule, LoadingModule, FooterModule, NavbarModule],
})
export class HomeModule {}
