import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthService } from '../services/app/auth.service';
import { AppService } from '../services/core/app.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrServiceCore } from '../services/core/toastr.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    public router: Router,
    private appService: AppService,
    private toastrServiceCore: ToastrServiceCore
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.init(next);
  }

  async init(next: ActivatedRouteSnapshot) {
    await this.appService.settings$.pipe(first((x) => x != null)).toPromise();
    if (this.appService.settings$.value.ISLOGINACTIVE == '0') {
      //this.toastrServiceCore.error('Yetkisiz')
      this.router.navigate(['/']);
      return false;
    }

    if (
      this.authService.loginStatus$.value === true &&
      this.authService.user$.value
    ) {
      // this.loginService.returnURL = next['_routerState'].url;
      this.toastrServiceCore.warning('Already logged in');
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
