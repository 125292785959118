export class RequestGetTicket {
  constructor(
    public Action: string,
    public Object: string,
    public Parameters: {
      HOTELID: number;
      TICKETDATE: string;
      LANG?: string;
    }
  ) {}
}
