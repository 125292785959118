import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppService } from '../services/core/app.service';
import { TranslateService } from '../services/core/translate.service';

@Pipe({
  name: 'translate',
  pure: false,
})
export class TranslatePipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private appService: AppService
  ) {}

  transform(key: string, args?: Array<object>): any {
    if (key.startsWith('You have the right to choose @@count seats')) {
    }
    return this.appService.language$.pipe(
      map((value) => {
        return this.translate.transform(key, args);
      })
    );
  }
}
