import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogRef } from 'src/app/dialog/config/dialog-ref';
import { DialogService } from 'src/app/dialog/service/dialog.service';

@Component({
  selector: 'app-online-pos-loading',
  templateUrl: './online-pos-loading.component.html',
  styleUrls: ['./online-pos-loading.component.css'],
})
export class OnlinePosLoadingComponent implements OnInit {
  @ViewChild('container', { read: ViewContainerRef, static: true })
  container: ViewContainerRef;

  @ViewChild('template', { read: TemplateRef, static: true })
  template: TemplateRef<any>;

  constructor(
    private sanitizer: DomSanitizer,
    protected dialogService: DialogService,
    private dialogRef: DialogRef,
  ) {}

  ngOnInit() {
    this.openCC(this.dialogService.dialogDetail.value);
  }
  onClose() {
    this.dialogRef.close();
  }

  html(content: string) {
    this.container.createEmbeddedView(this.template, {
      $implicit: this.sanitizer.bypassSecurityTrustHtml(content),
    });
    // content icinde <script> var ise script i calistir
    if (content.indexOf('<script') > -1) {
      // let script = document.createElement('script');
      setTimeout(() => {
        let contentScript = document.querySelector(
          '.html-dialog-container script',
        ).innerHTML;

        let fn = new Function(contentScript);
        fn();
      }, 2000);
    }
  }
  openCC(content: string) {
    this.html(content);
    if (
      content.indexOf('<script') == -1 &&
      content.indexOf('.submit()') == -1
    ) {
      setTimeout(() => {
        const form1 = document.querySelector(
          'form[name="downloadForm"]',
        ) as HTMLFormElement;
        const form2 = document.querySelector(
          'form[name="PostForm"]',
        ) as HTMLFormElement;
        const form3 = document.querySelector(
          'form[name="frmMpiForm"]',
        ) as HTMLFormElement;
        const form4 = document.querySelector(
          'form[name="webform0"]',
        ) as HTMLFormElement;
        const formFinansBank = document.querySelector(
          'form#step1Form',
        ) as HTMLFormElement;
        const formFinansBank2 = document.querySelector(
          'form#webform0',
        ) as HTMLFormElement;
        const formQuery = document.querySelector(
          '.html-dialog-container form',
        ) as HTMLFormElement;

        if (
          form1 ||
          form2 ||
          form3 ||
          form4 ||
          formFinansBank ||
          formFinansBank2
        ) {
          try {
            if (form1) {
              form1.submit();
            } else if (form2) {
              form2.submit();
            } else if (form3) {
              form3.submit();
            } else if (form4) {
              form4.submit();
            } else if (formFinansBank2) {
              formFinansBank2.submit();
            } else if (formFinansBank) {
              formFinansBank.action =
                'https://goguvenliodeme.bkm.com.tr/troy/approve';
              formFinansBank.method = 'POST';
              formFinansBank.submit();
            } else if (formQuery) {
              formQuery.submit();
            }
          } catch (e) {
            console.log('formunNameiStaticdeYok', e);
          }
        }
      }, 4000); // Garanti bankası bekletmediğim zaman direk hata veriyor. Bu kadar bekletince anca 3D formunu çiziyor.
    }
  }
}
