import { AppService } from 'src/app/services/core/app.service';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import {
  BasketConcertItem,
  BasketPreviewItem,
  BasketTicketItem,
} from 'src/app/models/(basket)/Basket.model';
import { BasketService } from 'src/app/services/app/basket.service';
import { TranslateService } from 'src/app/services/core/translate.service';

@Component({
  selector: 'basket-preview-item',
  templateUrl: './basket-preview-item.component.html',
  styleUrls: ['./basket-preview-item.component.css'],
})
export class BasketPreviewItemComponent implements OnInit {
  @Input() basketItem: BasketTicketItem | BasketConcertItem;
  @Input() type:
    | 'BasketTicketItem'
    | 'BasketConcertItem'
    | 'BasketExtraProductItem';
  @Input() index: number = 0;
  @Input() isShowImage: boolean = false;

  basketItemDetail: BasketPreviewItem;

  isShow: boolean = true;
  basketType: string;
  constructor(
    private basketService: BasketService,
    private translateService: TranslateService,
    private appService: AppService
  ) {
    this.basketService.basket$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data?.ITEMS?.[this.basketType]?.[this.index]) {
          this.basketItem = data.ITEMS?.[this.basketType]?.[this.index];
          this.mappingItem();
        }
      });
  }
  private destroy$ = new Subject<void>();
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit() {
    this.mappingItem();
  }

  isShowFunc(event) {
    this.isShow = !this.isShow;
    event.stopPropagation();
    this.goster();
  }

  goster() {
    console.log('basketItemDetail', this.basketItemDetail);
    console.log('basketItem', this.basketItem);
  }
  deleteThisItem() {
    this.basketService.deleteItem(this.type, this.index);
  }
  mappingItem() {
    moment.locale(this.appService.language$.value);
    if (this.type === 'BasketTicketItem') {
      this.basketType = 'TICKETITEMS';

      this.basketItemDetail = {
        ticketDate_date: moment(this.basketItem.BASKETDETAIL.CHECKDATE).get(
          'date'
        ),
        ticketDate_dddd: moment(this.basketItem.BASKETDETAIL.CHECKDATE)
          .locale(this.appService.language$.value)
          .format('dddd'),

        ticketDate_MMM: moment(this.basketItem.BASKETDETAIL.CHECKDATE)
          .locale(this.appService.language$.value)
          .format('MMM'),
        ticketDate_YYYY: moment(this.basketItem.BASKETDETAIL.CHECKDATE).format(
          'YYYY'
        ),
        TOTALPRICE: this.basketItem.BASKETDETAIL.TOTALPRICE,
        CHECKDATE: this.basketItem.BASKETDETAIL.CHECKDATE,
        NAME: this.basketItem.TICKETNAME,
        IMAGE: this.basketItem['TICKETURL'],
        TYPE: 'BasketTicketItem',
        SUBTEXT: '',
        CURRENCY: this.basketItem.CURRENCY,
      };
      if (this.basketItem.BASKETDETAIL['ADULTCOUNT'] > 0) {
        this.basketItemDetail.SUBTEXT =
          this.basketItem.BASKETDETAIL['ADULTCOUNT'] +
          ' ' +
          this.translateService.transform('Adult');
      }
      if (this.basketItem.BASKETDETAIL['CHILDRENCOUNT'] > 0) {
        this.basketItemDetail.SUBTEXT =
          this.basketItemDetail.SUBTEXT +
          ' / ' +
          this.basketItem.BASKETDETAIL['CHILDRENCOUNT'] +
          ' ' +
          this.translateService.transform('Children');
      }
    } else if (this.type === 'BasketConcertItem') {
      this.basketType = 'CONCERTITEMS';

      this.basketItemDetail = {
        ticketDate_date: moment(this.basketItem.BASKETDETAIL.CHECKDATE).get(
          'date'
        ),
        ticketDate_dddd: moment(this.basketItem.BASKETDETAIL.CHECKDATE)
          .locale(this.appService.language$.value)
          .format('dddd'),

        ticketDate_MMM: moment(this.basketItem.BASKETDETAIL.CHECKDATE).format(
          'MMM'
        ),
        ticketDate_YYYY: moment(this.basketItem.BASKETDETAIL.CHECKDATE).format(
          'YYYY'
        ),
        TOTALPRICE: this.basketItem.BASKETDETAIL.TOTALPRICE,
        CHECKDATE: this.basketItem.BASKETDETAIL.CHECKDATE,
        NAME: this.basketItem['SUBTICKETNAME'],
        IMAGE: this.basketItem['PRODUCTURL'],
        TYPE: 'BasketConcertItem',
        SUBTEXT: '',
        CURRENCY: this.basketItem.CURRENCY,
      };
      this.basketItemDetail.SUBTEXT =
        this.basketItem.BASKETDETAIL['COUNT'] +
        ' Adet ' +
        this.basketItem['TICKETTYPENAME'];
      if (this.basketItem?.['HALLINFO']) {
        this.basketItemDetail.OPTIONALTEXT = this.basketItem['HALLINFO'].SEATS;
      }
    } else if (this.type === 'BasketExtraProductItem') {
      this.basketType = 'EXTRAPRODUCTITEMS';

      this.basketItemDetail = {
        ticketDate_date: moment(this.basketItem.BASKETDETAIL.CHECKDATE).get(
          'date'
        ),
        ticketDate_dddd: moment(this.basketItem.BASKETDETAIL.CHECKDATE).format(
          'dddd'
        ),

        ticketDate_MMM: moment(this.basketItem.BASKETDETAIL.CHECKDATE).format(
          'MMM'
        ),
        ticketDate_YYYY: moment(this.basketItem.BASKETDETAIL.CHECKDATE).format(
          'YYYY'
        ),
        TOTALPRICE: this.basketItem.BASKETDETAIL.TOTALPRICE,
        CHECKDATE: this.basketItem.BASKETDETAIL.CHECKDATE,
        NAME: this.basketItem['NAME'],
        IMAGE: this.basketItem['PHOTOURL'],
        TYPE: 'BasketExtraProductItem',
        SUBTEXT: '',
        CURRENCY: this.basketItem.CURRENCY,
      };
      this.basketItemDetail.SUBTEXT =
        this.basketItem.BASKETDETAIL['COUNT'] + ' Adet ';
    }

    if (this.basketItem?.['PROMOTION']) {
      this.basketItemDetail['PROMOTION'] = this.basketItem?.['PROMOTION'];
    }
    // this.panelClick();
  }
}
