import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { WebPageModel, WebPages } from 'src/app/models/webpage';
import { AppService } from 'src/app/services/core/app.service';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit, OnDestroy {
  webpages$: BehaviorSubject<WebPageModel[]> = new BehaviorSubject<
    WebPageModel[]
  >([]);
  isMobile = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public appService: AppService,
  ) {
    this.breakpointObserver
      .observe(['(max-width: 599px)'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.isMobile = value.breakpoints['(max-width: 599px)'];
      });
  }
  ngOnInit() {
    this.appService.settings$
      .pipe(takeUntil(this.destroy$))
      .subscribe((settings) => {
        if (!settings) return;
        this.webpages$.next(
          WebPages.filter(
            (x) =>
              !settings.HIDEFOOOTERPAGES.includes(x.agreementType.toString()),
          ),
        );
      });
  }
  private destroy$ = new Subject<void>();
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
