import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BasketTicketItem } from 'src/app/models/(basket)/Basket.model';

@Component({
  selector: 'reservation-item',
  templateUrl: './reservation-item.component.html',
  styleUrls: ['./reservation-item.component.css'],
})
export class ReservationItemComponent implements OnInit {
  @Input() basketItem: BasketTicketItem;
  @Output() deleteItem = new EventEmitter<BasketTicketItem>();

  constructor() {}

  ngOnInit() {}

  deleteThisItem() {
    this.deleteItem.emit(this.basketItem);
  }
  goster() {
    console.log(this.basketItem);
  }
}
