import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { gatherXhrFromWindow, awaitUntil } from 'src/app/utils';
import { AppService } from './app.service';
import {
  TranslateLabels,
  TranslateLanguage,
} from 'src/app/models/TranslateLabels';

@Injectable({ providedIn: 'root' })
export class TranslateService {
  data: TranslateLanguage = new TranslateLanguage();

  dataGetEvent = new EventEmitter();

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private appService: AppService,
  ) {}

  transform(key: any, args?: Array<object>): any {
    let lang = this.appService.language$.value;

    if (args && args.length > 0) {
      let keyValue = this.data?.[lang]?.[key];
      if (keyValue !== undefined) {
        args.forEach((item) => {
          const property = Object.keys(item)[0];
          if (property) {
            const valueString = property;
            if (keyValue.indexOf(valueString) > -1) {
              keyValue = keyValue.replace(valueString, item[property]);
            }
          }
        });
      }
      return keyValue || key;
    } else {
      return this.data?.[lang]?.[key] || '#' + key + '#';
    }
  }

  getKey(str: string): string {
    return this.data[str] || str;
  }
}
