<div class="navbar-main">
  <nav class="navbar navbar-expand-lg container" style="padding: 0px">
    <a
      class="navbar-brand"
      routerLink="/"
      style="padding: 0px"
      *ngIf="appService.companyInfo$ | async as company"
    >
      <div>
        <img
          [src]="
            company?.LOGOURL
              ? company?.LOGOURL
              : 'https://www.eptera.com/wp-content/themes/astriol/assets/img/Logo.svg'
          "
          style="
            height: 75px;
            margin: 10px 0 10px 10px;
            width: auto;
            max-width: 150px;
          "
          width="150"
          alt="navbarMainLogo"
        />
      </div>
    </a>

    <!-- mobil -->
    <button
      id="basket-icon-navbar"
      type="button"
      *ngIf="isMobile && this.basketService.basket$?.value != null"
      [ngStyle]="{
        'background-color':
          this.basketService.basket$?.value != null ? 'green' : 'silver'
      }"
    >
      <div (click)="ShowBasketPanel()">
        <a>
          <i class="fa-sharp fa-solid fa-basket-shopping"></i>
          {{ "Basket" | translate | async }}
          <div class="basketCount" *ngIf="basketService.basket$?.value != null">
            ({{ getBasketItemLength() }})
          </div>
        </a>
        <ng-container [ngTemplateOutlet]="basketPreview"> </ng-container>
      </div>
    </button>
    <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
      <i class="fa-solid fa-bars"></i>
    </button>

    <div class="collapse navbar-collapse" [ngClass]="{ show: navbarOpen }">
      <div class="icon-right-top" *ngIf="authService?.user$ | async as user">
        <h6>
          <span style="color: #f7ff93">{{ user?.FULLNAME }} </span>
          <i class="fa-solid fa-circle-user"></i>
        </h6>
      </div>
      <ul class="navbar-nav">
        <li (click)="ShowBasketPanel()" *ngIf="!isMobile">
          <div>
            <a
              class="headerBasket"
              [ngStyle]="{
                'background-color':
                  this.basketService.basket$?.value != null ? 'green' : 'silver'
              }"
            >
              <i class="fa-sharp fa-solid fa-basket-shopping"></i>
              {{ "Basket" | translate | async }}
              <div
                class="basketCount"
                *ngIf="basketService.basket$?.value != null"
              >
                ({{ getBasketItemLength() }})
              </div>
            </a>
            <ng-container
              [ngTemplateOutlet]="basketPreview"
              *ngIf="this.basketService.basket$?.value != null"
            >
            </ng-container>
          </div>
        </li>

        <li
          (click)="goToExtraProduct()"
          *ngIf="
            appService.settings$.value?.EXTRAPRODUCTACTIVE &&
            appService.extraProducts$.value?.length > 0
          "
        >
          <div>
            <a
              [ngStyle]="{
                color:
                  this.basketService.basket$?.value != null ? 'white' : 'silver'
              }"
              ><i class="fa-solid fa-list"></i>
              {{ "Extra Services" | translate | async }}</a
            >
          </div>
        </li>
        <li *ngIf="appService.settings$.value?.ENTRANCETICKETACTIVE">
          <div>
            <a routerLink="/ticket"
              ><i class="fa-solid fa-ticket"></i>
              {{ "Tickets" | translate | async }}</a
            >
          </div>
        </li>

        <li *ngIf="appService.settings$.value?.CONCERTSALEACTIVE">
          <div>
            <a routerLink="/concert"
              ><i class="fa-solid fa-globe"></i>
              {{
                appService.settings$.value.NAVBARCONCERTLABEL
                  | translate
                  | async
              }}</a
            >
          </div>
        </li>

        <li
          *ngIf="
            !authService.loginStatus$.value &&
            appService.settings$.value?.ISLOGINACTIVE
          "
        >
          <div>
            <a routerLink="/login"
              ><i class="fa-solid fa-circle-user"></i>
              {{ "Login" | translate | async }}</a
            >
          </div>
        </li>
        <li
          *ngIf="
            !authService.loginStatus$.value &&
            appService.settings$.value?.ISLOGINACTIVE
          "
        >
          <div>
            <a routerLink="/register"
              ><i class="fa-solid fa-registered"></i>
              {{ "Register" | translate | async }}
            </a>
          </div>
        </li>
        <li
          *ngIf="
            authService?.loginStatus$.value &&
            appService.settings$.value?.ISLOGINACTIVE
          "
        >
          <div *ngIf="authService?.user$ | async as user">
            <a routerLink="/user">
              <i class="fa-solid fa-user-large"></i>
              {{ "Profile" | translate | async }}</a
            >
          </div>
        </li>
        <li
          *ngIf="
            authService.loginStatus$.value &&
            appService.settings$.value?.ISLOGINACTIVE
          "
        >
          <div>
            <a routerLink="/login" (click)="LogOut()"
              ><i class="fa-solid fa-registered"></i>
              {{ "Logout" | translate | async }}
            </a>
          </div>
        </li>
        <li
          style="position: relative"
          *ngIf="this.appService.languages$.value?.length > 1"
        >
          <!-- <basic-option [optionsObject]="langs"></basic-option> -->
          <i class="fa-solid fa-chevron-down"></i>
          <select
            class="form-select lang-select"
            [formControl]="langFormControl"
          >
            <optgroup
              style="color: gray"
              [label]="'Choose Language' | translate | async"
            >
              <option
                style="color: black"
                [selected]="
                  this.appService.language$.value == language.LANGUAGECODE
                "
                [value]="language.LANGUAGECODE"
                *ngFor="let language of this.appService.languages$.value"
              >
                {{ language.LANGUAGENAME || language.LANGUAGECODE }}
              </option>
            </optgroup>
          </select>
        </li>
      </ul>
    </div>
  </nav>
</div>

<ng-template #basketPreview>
  <div
    id="overlay"
    [ngStyle]="{ display: isShowBasketPanel ? 'block' : 'none' }"
  >
    &nbsp;
  </div>
  <div
    (click)="StopEvent($event)"
    id="basket-preview-panel"
    [ngStyle]="{
      transform: !isShowBasketPanel ? 'translateY(100%)' : 'translateY(0)'
    }"
  >
    <div class="basket-preview-main">
      <basket-preview-main></basket-preview-main>
    </div>
    <div
      class="basket-preview-main-footer"
      *ngIf="this.basketService.basket$.value"
    >
      <div class="basket-preview-main-footer-top">
        <span class="basket-preview-main-footer-top-text"
          >{{ "Total" | translate | async }}
        </span>
        <span class="basket-preview-main-footer-top-price"
          ><span *ngIf="this.basketService.basket$.value?.TOTALBASKETPRICE > 0">
            {{ this.basketService.basket$.value.TOTALBASKETPRICE }}
            {{ this.basketService.basket$.value.CURRENCY }}
          </span>
          <span *ngIf="this.basketService.basket$.value?.TOTALBASKETPRICE == 0">
            {{ "Free" | translate | async }}
          </span>
        </span>
      </div>
      <div
        class="basket-preview-main-footer-bottom"
        (click)="routeBasketPreview()"
      >
        <button class="basket-preview-main-footer-bottom-button">
          {{ "Go to Basket" | translate | async }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
