import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { UserGuard } from './guards/user.guard';
import { TicketGuard } from './guards/ticket.guard';
import { ConcertGuard } from './guards/concert.guard';
import { ExtraProductGuard } from './guards/extra-product.guard';
import { MainItemComponent } from './components/(items)/main-item/main-item.component';
import { ConcertGroupedGuard } from './guards/concert-grouped.guard';
// import { UploadMainComponent } from 'src/app/upload/upload-main/upload-main.component';

const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./wrapper-modules/main-item-wrapper.module').then(
        (m) => m.WrapperMainItemModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./wrapper-modules/login-wrapper.module').then(
        (m) => m.WrapperLoginModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./wrapper-modules/register-wrapper.module').then(
        (m) => m.WrapperRegisterModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./wrapper-modules/user-wrapper.module').then(
        (m) => m.WrapperUserModule
      ),
    canActivate: [UserGuard],
  },
  {
    path: 'ticket',
    loadChildren: () =>
      import('./wrapper-modules/ticket-wrapper.module').then(
        (m) => m.WrapperTicketModule
      ),
    canActivate: [TicketGuard],
  },
  {
    path: 'concert',
    loadChildren: () =>
      import('./wrapper-modules/concert-wrapper.module').then(
        (m) => m.WrapperConcertModule
      ),
    canActivate: [ConcertGuard],
  },
  {
    path: 'grouped-concert',
    loadChildren: () =>
      import('./wrapper-modules/main-item-wrapper.module').then(
        (m) => m.WrapperMainItemModule
      ),
    canActivate: [ConcertGroupedGuard],
  },
  {
    path: 'grouped-concert/:concertGroupedSeoUrl',
    loadChildren: () =>
      import('./wrapper-modules/concert-wrapper.module').then(
        (m) => m.WrapperConcertModule
      ),
    canActivate: [ConcertGroupedGuard],
  },
  {
    path: 'concert/:seoUrl',
    loadChildren: () =>
      import('./wrapper-modules/concert-detail-wrapper.module').then(
        (m) => m.WrapperConcertDetailModule
      ),
    canActivate: [ConcertGuard],
  },
  {
    path: 'extraProduct',
    loadChildren: () =>
      import('./wrapper-modules/extra-product-wrapper.module').then(
        (m) => m.WrapperExtraProductModule
      ),
    canActivate: [ExtraProductGuard],
  },
  {
    path: 'WebPage',
    loadChildren: () =>
      import('./wrapper-modules/webpage-wrapper.module').then(
        (m) => m.WrapperWebPageModule
      ),
  },
  {
    path: 'basket',
    loadChildren: () =>
      import('./wrapper-modules/basket-wrapper.module').then(
        (m) => m.WrapperBasketModule
      ),
  },
  {
    path: 'voucher',
    loadChildren: () =>
      import('./wrapper-modules/voucher-wrapper.module').then(
        (m) => m.WrapperVoucherModule
      ),
  },
  {
    path: 'complete',
    loadChildren: () =>
      import('./wrapper-modules/complete-wrapper.module').then(
        (m) => m.WrapperCompleteModule
      ),
  },

  { path: '**', redirectTo: '/', pathMatch: 'full' }, // redirect to `first-component`
];
@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
