import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'loading7',
  templateUrl: './loading7.component.html',
  styleUrls: ['./loading7.component.css']
})
export class Loading7Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
