<div
  class="basket-preview-main-wrapper"
  *ngIf="this.basketService.basket$.value"
>
  <div class="basket-preview-main-body">
    <div class="basket-preview-list">
      <div
        class="basket-preview-item"
        *ngFor="
          let basketItem of this.basketService.basket$.value.ITEMS.CONCERTITEMS;
          let i = index
        "
      >
        <basket-preview-item
          [basketItem]="basketItem"
          [type]="'BasketConcertItem'"
          [index]="i"
        ></basket-preview-item>
      </div>
      <div
        class="basket-preview-item"
        *ngFor="
          let basketItem of this.basketService.basket$.value.ITEMS.TICKETITEMS;
          let i = index
        "
      >
        <basket-preview-item
          [basketItem]="basketItem"
          [type]="'BasketTicketItem'"
          [index]="i"
          [isShowImage]="!this.ISSHOWSELECTTICKETDATE"
        ></basket-preview-item>
      </div>
      <div
        class="basket-preview-item"
        *ngFor="
          let basketItem of this.basketService.basket$.value.ITEMS
            .EXTRAPRODUCTITEMS;
          let i = index
        "
      >
        <basket-preview-item
          [basketItem]="basketItem"
          [type]="'BasketExtraProductItem'"
          [index]="i"
          [isShowImage]="true"
        ></basket-preview-item>
      </div>
    </div>
  </div>
</div>
