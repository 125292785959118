import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigService } from './config.service';

@NgModule({
  imports: [CommonModule],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          multi: true,
          useFactory: (config: ConfigService) => {
            return async () => {
              // config.findBearerToken();
              return await config.init();
            };
          },
          deps: [ConfigService],
        },
      ],
    };
  }
}
