<div class="homeMainDiv" #homeMainDiv>
  <navbar
    [SettingsPrimaryColor]="SettingsPrimaryColor"
    [SettingsSecondaryColor]="SettingsSecondaryColor"
  ></navbar>

  <div class="main" style="position: relative">
    <!--   <div class="container">
      <router-outlet></router-outlet>
    </div> -->
    <router-outlet></router-outlet>
  </div>
  <footer></footer>
</div>

<div class="load" *ngIf="!appService?.progress?.value">
  <loading7></loading7>
</div>
