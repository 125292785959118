import { Component, OnInit, enableProdMode } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DialogService } from 'src/app/dialog/service/dialog.service';
import { AppService } from 'src/app/services/core/app.service';
import { BasketService } from '../../../services/app/basket.service';
import { MainItemService } from 'src/app/services/app/main-item.service';
import { TranslateService } from 'src/app/services/core/translate.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  isOpenSidebarBasket: boolean = false;
  constructor(
    public dialogService: DialogService,
    public appService: AppService,
    public basketService: BasketService,
    public mainItemService: MainItemService
  ) {
    enableProdMode();

    if (this.appService.settings$.value == null) {
      if (
        localStorage.getItem('settings') &&
        localStorage.getItem('companyInfo') &&
        localStorage.getItem('languages')
      ) {
        this.appService.setWebsite(
          JSON.parse(localStorage.getItem('settings')),
          JSON.parse(localStorage.getItem('companyInfo')),
          JSON.parse(localStorage.getItem('languages'))
        );
      } else {
        this.mainItemService.getSettings();
      }
    }
    if (localStorage.getItem('basket-buy-later'))
      this.appService.openBuyLaterDialog();
  }

  isOpenSidebarBasketFunc() {
    this.isOpenSidebarBasket = !this.isOpenSidebarBasket;
  }
  SettingsPrimaryColor;
  SettingsSecondaryColor;
  //TODO
  Settings$ = new BehaviorSubject<any>(null);

  ngOnInit() {}
}
