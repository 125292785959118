import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DialogConfig } from 'src/app/dialog/config/dialog-config';
import { DialogRef } from 'src/app/dialog/config/dialog-ref';
import { DialogService } from 'src/app/dialog/service/dialog.service';
import { BasketService } from 'src/app/services/app/basket.service';

@Component({
  selector: 'app-text-dialog',
  templateUrl: './text-dialog.component.html',
  styleUrls: ['./text-dialog.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TextDialogComponent {
  constructor(
    public config: DialogConfig,
    public dialog: DialogRef,
    protected dialogService: DialogService,
    protected basketService: BasketService,
    private router: Router
  ) {
    console.log(this.dialogService.dialogDetail.value);
    console.log(this.dialogService.dialogDetailConfig.value);
  }
  goster() {
    console.log(this.dialogService.dialogDetail.value);
    console.log(this.dialogService.dialogDetailConfig.value);
  }
  onSubmit() {
    this.basketService.basket$.next(this.dialogService.dialogDetail.value.data);
    this.router.navigate(['/basket']);
    this.onClose();
  }
  onClose(): void {
    this.dialog.close('some value');
  }
}
