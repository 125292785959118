import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HomeComponent } from './components/(core)/home/home.component';

import { ImportModule } from './import.module';
import { DialogModule } from './dialog/components/dialog/dialog.module';
import { HomeModule } from './components/(core)/home/home.module';

import { SharedModule } from './initializer/shared.module';
import { AppRoutingModule } from './route.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BasketModule } from './components/(basket)/basket/basket.module';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    SharedModule.forRoot(),
    AppRoutingModule,
    DialogModule,
    ImportModule,
    HomeModule,
    BrowserAnimationsModule,
  ],
  bootstrap: [HomeComponent],
})
export class AppModule {}
