import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';

const modules = [RouterModule, CommonModule];
@NgModule({
  exports: modules,
  imports: [
    ...modules,
    ToastrModule.forRoot({ positionClass: 'toast-top-right' }),
  ],
})
export class ImportModule {}
