import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImportModule } from 'src/app/import.module';
import { BasicOptionComponent } from './basic-option.component';

@NgModule({
  imports: [ImportModule, FormsModule, ReactiveFormsModule],
  declarations: [BasicOptionComponent],
  exports: [BasicOptionComponent],
})
export class BasicOptionModule {}
