export class TranslateLabels {
  'NO_CONCERT_TEXT_1': string;
  'NO_CONCERT_TEXT_2': string;
  'NO_CONCERT_TEXT_3': string;
  'Events': string;
  'Add Events': string;
  'AboutUs': string;
  'Post Code': string;
  'KVKK': string;
  'Grouped Concert List': string;
  'Seats Attached to The Basket': string;
  'Go to the Cart': string;
  'Yetişkin': string;
  'Çocuk': string;
  'Confirm the Seat Selection': string;
  'Confirm the Seat Selection and Go to the Cart': string;
  'An error occurred while registering the ticket. Please try again.': string;
  'Choose Language': string;
  'Stadium entrance is free when you buy a park entrance ticket': string;
  'Seat Numbers': string;
  Dear: string;
  Back: string;
  Home: string;
  'Please choose a date': string;
  'Your reservation has been created. You can participate in the event with a QR code': string;
  'Ticket Date': string;
  'Pnr Number': string;
  'the 15-minute timeout has expired. Please select the ticket again.': string;
  Name: string;
  Surname: string;
  TicketInformation: string;
  'Ticket Count': string;
  'Total Price': string;
  Basket: string;
  'Use Agreement': string;
  'Read And Accept': string;
  'Complete The Purchase': string;
  'Continue Reservation': string;
  'Buy Later': string;
  'Clear Basket': string;
  Free: string;
  'Contact Information': string;
  'Bill Information': string;
  Individual: string;
  Corporate: string;
  City: string;
  Citysub: string;
  'Select City': string;
  'Savecheck Error': string;
  'Send Sms and Email': string;
  Email: string;
  Phone: string;
  'Tax Number': string;
  'Zip Code': string;
  'Credit Card Declined': string;
  'This seat is closed for sale': string;
  LBL_CARDERROR_FIRSTLINE: string;
  LBL_CARDERROR_SECONDLINE: string;
  LBL_CARDERROR_THIRDLINE: string;
  LBL_CARDERROR_FOURTHLINE: string;
  LBL_CARDERROR_FIFTHLINE: string;
  LBL_CARDERROR_SIXTHLINE: string;
  LBL_CARDERROR_SEVENTHLINE: string;
  LBL_CARDERROR_EIGHTHLINE: string;
  LBL_CARDERROR_NINETHLINE: string;
  'Concert Information': string;
  'Invoice Info': string;
  'Extra Services Information': string;
  PARK_CLOSE_SELL1: string;
  PARK_CLOSE_SELL2: string;
  PARK_CLOSE_SELL3: string;
  PARK_CLOSE_SELL4: string;
  PARK_CLOSE_SELL5: string;
  PARK_CLOSE_SELL6: string;
  Info: string;
  Agreements: string;
  Links: string;
  Contact: string;
  'Pay Up': string;
  'Card Holder': string;
  'Card Number': string;
  'Not Found Ticket Date': string;
  'Add to Cart': string;
  Close: string;
  Children: string;
  Adult: string;
  Date: string;
  'Select Date': string;
  'Go to Basket': string;
  'Extra Services': string;
  Total: string;
  'Promotion Code Error': string;
  'Promotion Code': string;
  'Ticket Type': string;
  'Concert Date': string;
  'Event Date': string;
  'Concert End Hour': string;
  'Concert Start Hour': string;
  'Event End Hour': string;
  'Event Start Hour': string;
  Location: string;
  Rules: string;
  QUANTITY: string;
  Detail: string;
  'Concert List': string;
  Logout: string;
  Profile: string;
  Register: string;
  Login: string;
  Concerts: string;
  Tickets: string;
  Ticket: string;
  'Ticket Name': string;
  Price: string;
  'Identity Number': string;
  Address: string;
  BTN_PDF: string;
  FOOTER_PRIVACY_AGREEMENT: string;
  FOOTER_SALE_AGREEMENT: string;
  CancellationAgreement: string;
  Buy: string;
  'The Sold-Out Seat': string;
  'All seats selected': string;
  'You have the right to choose @@count seats': string;
  'You can select up to @@count  seats.': string;
  'Available Seat': string;
  'Selected Seats': string;
  'Selected Seat': string;
  'Clear Selected Seats': string;
  'Out Of Sales': string;
  'Incompatible Ticket Type': string;
  'Not Found Phone': string;
  'The entrance has been made': string;
  'Could not log in': string;
  'The SMS code was entered incorrectly.': string;
  'This number is not registered': string;
  'Sms Was Sent.': string;
  'Sms Could Not Be Sent': string;
  'Please enter a valid phone number.': string;
  'The password has been sent': string;
  'The password could not be sent. Please try again later.': string;
  'Registration is successful': string;
  'Please check the information you have entered.': string;
  'Registration failed': string;
  'Please fill in the required fields': string;
  'No ticket was found': string;
  'Please check the check box.': string;
  'Please check the form.': string;
  'Your basket has been cleaned.': string;
  'Later, your purchase request was successfully registered.': string;
  'An error occurred during the process. Please try again.': string;
  'You can get a maximum of @@count from the @@name ticket in your cart.': string;
  'This discount does not apply to the product with the promo code in your cart.': string;
  'Odeme Could not be Found.': string;
  'Pay Failed.': string;
  'The reservation could not be saved. Please let us know.': string;
  'The Reservation Could not be Found.': string;
  'E-Mail Has Been Sent': string;
  'The Mail Could not be Sent.': string;
  'There are no products in your cart.': string;
  'The Pay Could not be Completed.Let us know.': string;
  'PNR not found': string;
  'An error has occurred. Please try again.': string;
  'The concert could not be found.': string;
  'Please select the number of tickets.': string;
  'The quota is full.': string;
  'You cannot purchase more than the quota.': string;
  'Please enter the promotional code.': string;
  'The promo code could not be found': string;
  'The promo code @@beforeName is not valid for the ticket type. Changed to @@newName.': string;
  'Seat selection is successful': string;
  'The seat selection has been cancelled.': string;
  'This seat is unelectable. Change the ticket type.': string;
  'This seat has been sold': string;
  'This seat is defective': string;
  'Please select @@name number of seats': string;
  'Please select the MOQ of the product.': string;
  'No product was found in your cart. Please add the product.': string;
  'The ticket was added to the cart.': string;
  'At least 1 ticket must be selected.': string;
  'No tickets were found for the date you selected, please change the date.': string;
  'Tickets are only available on @@message day.': string;
  'Please enter a valid Turkish ID number.': string;
  'An error occurred while filling in the card information.': string;
  'The contract could not be found.': string;
  'Already logged in': string;
  'The selected ticket could not be found. Please select a ticket.': string;
  'Please log in first to enter the profile.': string;
  'No product was found in your cart. Please add product.': string;
  'Concerts with Different Dates Cannot be Added to the Cart': string;
  'The Concert Was Added to the Cart': string;
  'Extra Products have Been Added to the Cart': string;
  'The Extra Product Has Been Deleted from the Cart': string;
  'The Product Was Deleted from the Cart': string;
  'The appropriate payment options for your card could not be obtained. Please try again later.': string;
  'An error occurred while saving your cart.Please try again soon.': string;
  'You can buy a maximum of @@quota tickets.': string;
  'Please check your card information.': string;
  'Pay Online': string;
  'Other Categories': string;
  'Person Count': string;  
}
export class Language {
  LANGUAGENAME: string;
  LANGUAGECODE: string;
}

export class TranslateLanguage {
  TR: TranslateLabels;
  EN: TranslateLabels;
  RU: TranslateLabels;
  DE: TranslateLabels;

  constructor() {
    this.TR = {
      NO_CONCERT_TEXT_1: 'Heyecan Verici Etkinlikler Yolda!',
      NO_CONCERT_TEXT_2: `Şu an için aktif bir biletimiz bulunmuvor, ancak endişelenmevin!
      Sizler için dopdolu bir etkinlik takvimi üzerinde calisiyoruz ve
      yakinda harika firsatlarla geri dönecediz!`,
      NO_CONCERT_TEXT_3:
        'Yeni biletlerimiz tekrar satışta oldugunda kaçırmamak için bizi takip etmeyi unutmayin.',
      Events: 'Etkinlikler',
      'Add Events': 'Etkinlik Ekle',
      AboutUs: 'Hakkımızda',
      'Post Code': 'Posta Kodu',
      'Grouped Concert List': 'Gruplanmış Konser Listesi',
      'Go to the Cart': 'Sepete Git',
      'Seats Attached to The Basket': 'Sepete Ekli Olan Koltuklar',
      Yetişkin: 'Yetişkin',
      Çocuk: 'Çocuk',
      'Confirm the Seat Selection': 'Koltuk Seçimini Onayla',
      'Confirm the Seat Selection and Go to the Cart':
        'Koltuk Seçimini Onayla ve Sepete Git',
      'Event End Hour': 'Etkinlik Bitiş Saati',
      'Event Start Hour': 'Etkinlik Başlangıç Saati',
      'Person Count': 'Kişi Sayısı',
      'KVKK': 'KVKK',
      'Event Date': 'Etkinlik Tarihi',
      'Stadium entrance is free when you buy a park entrance ticket':
        'Park giriş biletinizi aldığınızda stadyum girişi ücretsizdir.',
      'An error occurred while registering the ticket. Please try again.':
        'Bilet kaydı yapılırken bir hata oluştu. Lütfen tekrar deneyiniz.',
      'Please choose a date': 'Lütfen tarih seçiniz',
      'the 15-minute timeout has expired. Please select the ticket again.':
        '15 dakikalık zaman aşımı süresi doldu. Lütfen biletinizi tekrar seçiniz.',
      'Other Categories': 'Diğer Kategoriler',
      'This seat is closed for sale': 'Bu koltuk satışa kapatılmıştır',
      'Choose Language': 'Dil Seçiniz',
      'Seat Numbers': 'Koltuk Numaraları',
      Dear: 'Sayın',
      'Your reservation has been created. You can participate in the event with a QR code':
        'Rezervasyonunuz oluşturuldu. QR kod ile etkinliğe katılabilirsiniz',
      'All seats selected': 'Tüm koltuklar seçildi',
      Back: 'Geri',
      Home: 'Ana sayfa',
      'Ticket Date': 'Bilet Tarihi',
      'Pnr Number': 'Pnr Numarası',
      'Contact Information': 'İletişim Bilgileri',
      Name: 'İsim',
      Surname: 'Soyad',
      TicketInformation: 'Bilet Bilgisi',
      'Ticket Count': 'Bilet Sayısı',
      'Total Price': 'Toplam Fiyat',
      Basket: 'Sepet',
      'Use Agreement': 'Kullanım Sözleşmesi',
      'Read And Accept': 'Okudum ve Kabul Ediyorum',
      'Complete The Purchase': 'Satın Alma Tamamla',
      'Continue Reservation': 'Rezervasyona Devam et',
      'Buy Later': 'Daha Sonra Satın Al',
      'Clear Basket': 'Sepeti Temizle',
      Free: 'Ücretsiz',
      'Bill Information': 'Fatura Bilgisi',
      Individual: 'Bireysel',
      Corporate: 'Kurumsal',
      City: 'İl',
      Citysub: 'İlçe',
      'Select City': 'Lütfen il seçiniz.',
      'Savecheck Error':
        'Hata meydana geldi. Lütfen sayfayı yenileyip tekrar deneyin. Aynı sorun meydana gelmesi halinde Sayfa linki ile ve ya PNR numarası ile bize bildirin.',
      'Send Sms and Email': 'Sms ve Mail iletiliyor lütfen bekleyiniz.',
      Email: 'Eposta',
      Phone: 'Telefon Numarası',
      'Tax Number': 'Vergi Numarası',
      'Zip Code': 'Zip Kodu',
      'Credit Card Declined': 'Kredi Kartı Hatası',
      LBL_CARDERROR_FIRSTLINE: 'Ödemeniz yapılamadı.',
      LBL_CARDERROR_SECONDLINE: 'Kredi Kartınız Reddedildi.',
      LBL_CARDERROR_THIRDLINE:
        'İşleminiz tamamlanamadı.Rezervasyonunuzun onaylanması için ödemenizi bekliyoruz, lütfen ödemeyi tamamladıktan sonra rezervasyonunuzun onaylanacağını ve belgenizi anında alacağınızı unutmayın.',
      LBL_CARDERROR_FOURTHLINE: 'Kartınızda limit yetersizliği olabilir',
      LBL_CARDERROR_FIFTHLINE: 'Bankanız tarafından engellenmiş olabilir',
      LBL_CARDERROR_SIXTHLINE: 'Ödemeniz günlük limitinizi aşmış olabilir',
      LBL_CARDERROR_SEVENTHLINE:
        'Bankamatik veya başka kart ile deneyebilirsiniz.',
      LBL_CARDERROR_EIGHTHLINE: 'Kredi kartınız iptal edilmiş olabilir',
      LBL_CARDERROR_NINETHLINE:
        'Banka ile sizin aranızda özel bir durum olabilir, bu durumda bankanız ile iletişime geçmelisiniz.',
      'Concert Information': 'Konser Bilgisi',
      'Invoice Info': 'Fatura Bilgisi',
      'Extra Services Information': 'Ekstra Servis Bilgileri',
      PARK_CLOSE_SELL1: 'Tesisimiz geçici süreliğine satışa kapatılmıştır',
      PARK_CLOSE_SELL2: 'Lütfen bizimle',
      PARK_CLOSE_SELL3: 'Buraya Tıklayarak',
      PARK_CLOSE_SELL4: 've ya',
      PARK_CLOSE_SELL5: 'arayarak iletişim kurunuz.',
      PARK_CLOSE_SELL6: 'Anlayışınız için teşekkürler.',
      Info: 'Bilgilendirme',
      Agreements: 'Sözleşmeler',
      Links: 'Bağlantılar',
      Contact: 'İletişim',
      'Pay Up': 'Ödeme Yap',
      'Card Holder': 'Kart Sahibi',
      'Card Number': 'Kart Numarası',
      'Not Found Ticket Date': 'Belirtilen tarihte kart numarası bulunamadı.',
      'Add to Cart': 'Sepete Ekle',
      Close: 'Kapat',
      Children: 'Çocuk',
      Adult: 'Yetişkin',
      Date: 'Tarih',
      'Select Date': 'Lütfen tesise gelmek istediğiniz tarihi seçiniz.',
      'Go to Basket': 'Sepete git',
      'Extra Services': 'Ekstra Servisler',
      Total: 'Toplam',
      'Promotion Code Error': 'Promosyon bulunamadı',
      'Promotion Code': 'Promosyon Kodu',
      'Ticket Type': 'Bilet Tipi',
      'Concert Date': 'Konser Tarihi',
      'Concert End Hour': 'Konser Bitiş Saati',
      'Concert Start Hour': 'Konser Başlangıç Saati',
      Location: 'Konum',
      Rules: 'Kurallar',
      Detail: 'Detaylar',
      'Concert List': 'Konser Listesi',
      Logout: 'Çıkış Yap',
      Profile: 'Profil',
      Register: 'Kayıt Ol',
      Login: 'Giriş Yap',
      Concerts: 'Konserler',
      Tickets: 'Biletler',
      Ticket: 'Bilet',
      'Ticket Name': 'Bilet İsmi',
      Price: 'Fiyat',
      'Identity Number': 'Tc Kimlik Numarası',
      Address: 'Adres',
      BTN_PDF: 'Pdf olarak indir',
      FOOTER_PRIVACY_AGREEMENT: 'Gizlilik Sözleşmesi',
      FOOTER_SALE_AGREEMENT: 'Satış Sözleşmesi',
      CancellationAgreement: 'İade Sözleşmesi',
      Buy: 'Satın Al',
      'The Sold-Out Seat': 'Satılmış Koltuk',
      'You can select up to @@count  seats.':
        'En fazla @@count koltuk seçebilirsiniz.',
      'Available Seat': 'Kullanılabilir Koltuk',
      'Selected Seats': 'Seçili Koltuklar',
      'Selected Seat': 'Seçili Koltuk',
      'Clear Selected Seats': 'Seçili Koltukları Temizle',
      'Out Of Sales': 'Satış Dışı',
      'Incompatible Ticket Type': 'Uyumsuz Bilet Tipi',
      'Not Found Phone': 'Bu numara kayıtlı değil',
      'The entrance has been made': 'Giriş yapıldı',
      'Could not log in': 'Giriş yapılamadı',
      'The SMS code was entered incorrectly.': 'SMS kodu yanlış girildi.',
      'This number is not registered': 'Bu numara kayıtlı değil',
      'Sms Was Sent.': 'Sms Gönderildi.',
      'Sms Could Not Be Sent': 'Sms Gönderilemedi',
      'Please enter a valid phone number.':
        'Lütfen geçerli bir telefon numarası giriniz.',
      'The password has been sent': 'Şifre gönderildi',
      'The password could not be sent. Please try again later.':
        'Şifre gönderilemedi. Lütfen daha sonra tekrar deneyiniz.',
      'Registration is successful': 'Kayıt başarılı',
      'Please check the information you have entered.':
        'Girdiğiniz bilgileri kontrol ediniz.',
      'Registration failed': 'Kayıt başarısız',
      'Please fill in the required fields':
        'Lütfen gerekli alanları doldurunuz',
      'No ticket was found': 'Bilet bulunamadı',
      'Please check the check box.': 'Lütfen onay kutucuğunu işaretleyiniz.',
      'Please check the form.': 'Lütfen formu kontrol ediniz.',
      'Your basket has been cleaned.': 'Sepetiniz temizlendi.',
      'Later, your purchase request was successfully registered.':
        'Daha sonra satın alma talebiniz başarıyla kaydedilmiştir.',
      'An error occurred during the process. Please try again.':
        'İşlem sırasında bir hata oluştu. Lütfen tekrar deneyiniz.',
      'You can get a maximum of @@count from the @@name ticket in your cart.':
        'Sepetinizde @@name biletinden en fazla @@count adet alabilirsiniz.',
      'This discount does not apply to the product with the promo code in your cart.':
        'Sepetinizdeki promosyon kodlu ürün için bu indirim geçerli değil.',
      'The Reservation Could not be Found.': 'Rezervasyon Bulunamadı.',
      'Odeme Could not be Found.': 'Ödeme Bulunamadı.',
      'Pay Failed.': 'Ödeme Başarısız.',
      'The reservation could not be saved. Please let us know.':
        'Rezervasyon kaydedilemedi. Lütfen bize bildiriniz.',
      'E-Mail Has Been Sent': 'Mail Gönderildi',
      'The Mail Could not be Sent.': 'Mail Gönderilemedi.',
      'There are no products in your cart.':
        'Sepetinizde ürün bulunmamaktadır.',
      'The Pay Could not be Completed.Let us know.':
        'Ödeme Tamamlanamadı.Bize bildiriniz.',
      'PNR not found': 'PNR bulunamadı',
      'An error has occurred. Please try again.':
        'Bir hata meydana geldi. Lütfen tekrar deneyiniz.',
      'The concert could not be found.': 'Konser bulunamadı.',
      'Please select the number of tickets.': 'Lütfen bilet adedini seçiniz.',
      'The quota is full.': 'Kontenjan dolu.',
      'You cannot purchase more than the quota.':
        'Kontenjandan fazla alım yapamazsınız.',
      'Please enter the promotional code.': 'Lütfen promosyon kodunu giriniz.',
      'The promo code could not be found': 'Promosyon kodu bulunamadı',
      'The promo code @@beforeName is not valid for the ticket type. Changed to @@newName.':
        'Promosyon kodu @@beforeName bilet tipi için geçerli değil. @@newName olarak değiştirildi.',
      'Seat selection is successful': 'Koltuk seçimi başarılı',
      'The seat selection has been cancelled.': 'Koltuk seçimi iptal edildi.',
      'This seat is unelectable. Change the ticket type.':
        'Bu koltuk seçilemez. Bilet tipini değiştiriniz.',
      'This seat has been sold': 'Bu koltuk satılmıştır',
      'This seat is defective': 'Bu koltuk arızalıdır',
      'Please select @@name number of seats':
        'Lütfen @@name adet koltuk seçiniz',
      'Please select the MOQ of the product.': 'Lütfen ürün adedini seçiniz.',
      'No product was found in your cart. Please add the product.':
        'Sepetinizde ürün bulunamadı. Lütfen ürün ekleyiniz.',
      'The ticket was added to the cart.': 'Bilet sepete eklendi.',
      'At least 1 ticket must be selected.': 'En az 1 adet bilet seçilmelidir.',
      'No tickets were found for the date you selected, please change the date.':
        'Seçtiğiniz tarihe uygun bilet bulunamadı lütfen tarihi değiştiriniz.',
      'Tickets are only available on @@message day.':
        'Bilet sadece @@message günü kullanılabilir.',
      'Please enter a valid Turkish ID number.':
        'Lütfen geçerli bir TC kimlik numarası giriniz.',
      'An error occurred while filling in the card information.':
        'Kart bilgilerini doldururken hata meydana geldi.',
      'The contract could not be found.': 'Sözleşme bulunamadı.',
      'Already logged in': 'Zaten giriş yapıldı',
      'The selected ticket could not be found. Please select a ticket.':
        'Seçili bilet bulunamadı. Lütfen bilet seçiniz.',
      'Please log in first to enter the profile.':
        'Profile girmek için önce lütfen giriş yapınız.',
      'No product was found in your cart. Please add product.':
        'Sepetinizde ürün bulunamadı. Lütfen ürün ekleyiniz.',
      'Concerts with Different Dates Cannot be Added to the Cart':
        'Farklı Tarihli Konserler Sepete Eklenemez',
      'The Concert Was Added to the Cart': 'Konser Sepete Eklendi',
      'Extra Products have Been Added to the Cart':
        'Ekstra Ürün Sepete Eklendi',
      'The Extra Product Has Been Deleted from the Cart':
        'Ekstra Ürün Sepetten Silindi',
      'The Product Was Deleted from the Cart': 'Ürün Sepetten Silindi',
      'The appropriate payment options for your card could not be obtained. Please try again later.':
        'Kartınıza uygun ödeme seçenekleri alınamadı. Lütfen daha sonra tekrar deneyiniz.',
      'An error occurred while saving your cart.Please try again soon.':
        'Sepetenizi kaydederken bir hata meydana geldi.Lütfen birazdan tekrar deneyiniz.',
      'You can buy a maximum of @@quota tickets.':
        'En fazla @@quota adet bilet alabilirsiniz.',
      'Please check your card information.':
        'Lütfen kart bilgilerinizi kontrol ediniz.',
      'Pay Online': 'Online Ödeme',
      QUANTITY: 'ADET',
      'You have the right to choose @@count seats':
        '@@count adet koltuk seçme hakkınız bulunmaktadır.',
    };
    this.RU = {
      NO_CONCERT_TEXT_1: 'Грядут захватывающие События!',
      NO_CONCERT_TEXT_2: `Ты хочешь, чтобы я сделал все, что в моих силах, чтобы я сделал все, что в моих силах". Я хочу, чтобы ты сделал все, что в моих силах, чтобы ты понял, что я хочу, чтобы ты сделал все, что в моих силах!`,
      NO_CONCERT_TEXT_3:
        'Не забудьте подписаться на нас, чтобы не пропустить наши новые билеты, когда они снова поступят в продажу.',
      Events: 'События',
      'Add Events': 'Добавить события',
      AboutUs: 'О нас',
      'Post Code': 'Почтовый индекс',
      'Grouped Concert List': 'Сгруппированный список концертов',
      'Go to the Cart': 'Перейти в корзину',
      'Seats Attached to The Basket': 'Места, прикрепленные к корзине',
      Yetişkin: 'Взрослый',
      Çocuk: 'Ребенок',
      'KVKK': 'Закон о защите персональных данных',
      'Confirm the Seat Selection': 'Подтвердите выбор места',
      'Confirm the Seat Selection and Go to the Cart':
        'Подтвердите выбор места и перейдите в корзину',
      'Event End Hour': 'Время окончания мероприятия',
      'Event Start Hour': 'Время начала мероприятия',
      'Person Count': 'Количество человек',
      'Event Date': 'Дата мероприятия',
      'Stadium entrance is free when you buy a park entrance ticket':
        'При покупке билета на вход в парк вход на стадион бесплатный.',
      'An error occurred while registering the ticket. Please try again.':
        'При регистрации билета произошла ошибка. Пожалуйста, попробуйте еще раз.',
      'Please choose a date': 'Пожалуйста, выберите дату',
      'the 15-minute timeout has expired. Please select the ticket again.':
        '15-минутный тайм-аут истек. Пожалуйста, выберите билет еще раз.',
      'This seat is closed for sale': 'Это место закрыто для продажи',
      'Other Categories': 'Другие категории',
      'Choose Language': 'Выберите язык',
      'Seat Numbers': 'Номера мест',
      Dear: 'Уважаемый',
      'Your reservation has been created. You can participate in the event with a QR code':
        'Ваше бронирование было создано. Вы можете принять участие в мероприятии с помощью QR-кода',
      'All seats selected': 'Все места выбраны',
      'You have the right to choose @@count seats':
        'У вас есть право выбрать @@count мест',
      Back: 'Назад',
      Home: 'Домашняя страница',
      'Ticket Date': 'Дата билета',
      'Pnr Number': 'PNR-номер',
      'Contact Information': 'Контактная информация',
      Name: 'Имя',
      Surname: 'Фамилия',
      TicketInformation: 'Информация о билетах',
      'Ticket Count': 'Количество билетов',
      'Total Price': 'Итоговая цена',
      Basket: 'Корзина',
      'Use Agreement': 'Соглашение об использовании',
      'Read And Accept': 'Прочтите и примите',
      'Complete The Purchase': 'Завершить покупку',
      'Continue Reservation': 'Продолжить бронирование',
      'Buy Later': 'Купить позже',
      'Clear Basket': 'Очистить корзину',
      Free: 'бесплатный',
      'Bill Information': 'Информация о счете',
      Individual: 'Индивидуальный',
      Corporate: 'Корпоративный',
      City: 'Город',
      Citysub: 'городок',
      'Select City': 'Пожалуйста, выберите город',
      'Savecheck Error':
        'Произошла ошибка. Пожалуйста, обновите страницу и повторите попытку. Если возникнет та же проблема, сообщите нам об этом, указав ссылку на страницу или номер PNR.',
      'Send Sms and Email': 'Отправка SMS и Mail, пожалуйста, подождите.',
      Email: 'Электронная почта',
      Phone: 'Телефон',
      'Tax Number': 'Налоговый номер',
      'Zip Code': 'Почтовый индекс',
      'Credit Card Declined': 'Кредитная карта отклонена',
      LBL_CARDERROR_FIRSTLINE: 'Ваш платеж не может быть произведен.',
      LBL_CARDERROR_SECONDLINE: 'Ваша кредитная карта была отклонена.',
      LBL_CARDERROR_THIRDLINE:
        'Ваша транзакция не может быть завершена. Мы ждем вашего платежа, чтобы подтвердить бронирование. Обратите внимание, что после завершения платежа ваше бронирование будет подтверждено, и вы сразу же получите свой документ.',
      LBL_CARDERROR_FOURTHLINE: 'У вашей карты может быть лимит',
      LBL_CARDERROR_FIFTHLINE: 'Он может быть заблокирован вашим банком.',
      LBL_CARDERROR_SIXTHLINE:
        'Возможно, ваш платеж превысил ваш дневной лимит',
      LBL_CARDERROR_SEVENTHLINE:
        'Вы можете попробовать с дебетовой или другой карты.',
      LBL_CARDERROR_EIGHTHLINE: 'Ваша кредитная карта могла быть аннулирована',
      LBL_CARDERROR_NINETHLINE:
        'Между вами и банком может возникнуть особая ситуация, и в этом случае вам следует обратиться в свой банк.',
      'Concert Information': 'Информация о концерте',
      'Invoice Info': 'Информация о счете',
      'Extra Services Information': 'Информация о дополнительных услугах',
      PARK_CLOSE_SELL1: 'Наш объект временно закрыт на продажу.',
      PARK_CLOSE_SELL2: 'Пожалуйста, с нами',
      PARK_CLOSE_SELL3: 'Кликните сюда',
      PARK_CLOSE_SELL4: 'или',
      PARK_CLOSE_SELL5: 'Свяжитесь с нами по телефону.',
      PARK_CLOSE_SELL6: 'Спасибо за Ваше понимание.',
      Info: 'Информация',
      Agreements: 'Соглашения',
      Links: 'связь',
      Contact: 'Контакт',
      'Pay Up': 'Платить',
      'Card Holder': 'Держатель карты',
      'Card Number': 'Номер карты',
      'Not Found Ticket Date': 'Номер карты не найден на указанную дату',
      'Add to Cart': 'добавить в корзину',
      Close: 'Закрывать',
      Children: 'дети',
      Adult: 'Взрослый',
      Date: 'Дата',
      'Select Date':
        'Пожалуйста, выберите дату, когда вы хотите приехать в отель.',
      'Go to Basket': 'Перейти в корзину',
      'Extra Services': 'Дополнительный сервис',
      Total: 'Общий',
      'Promotion Code Error': 'Ошибка промо-кода',
      'Promotion Code': 'Промо-Код',
      'Ticket Type': 'Тип билета',
      'Concert Date': 'Дата концерта',
      'Concert End Hour': 'Час окончания концерта',
      'Concert Start Hour': 'Время начала концерта',
      Location: 'расположение',
      Rules: 'Правила',
      Detail: 'Деталь',
      'Concert List': 'Список концертов',
      Logout: 'Выйти',
      Profile: 'Профиль',
      Register: 'регистр',
      Login: 'Авторизоваться',
      Concerts: 'Концерты',
      Tickets: 'Билеты',
      Ticket: 'Билет',
      'Ticket Name': 'Название билета',
      Price: 'Цена',
      'Identity Number': 'Идентификационный номер',
      Address: 'Адрес',
      BTN_PDF: 'Скачать в формате PDF',
      FOOTER_PRIVACY_AGREEMENT: 'Соглашение о конфиденциальности',
      FOOTER_SALE_AGREEMENT: 'Договор купли-продажи',
      CancellationAgreement: 'Соглашение об отмене',
      Buy: 'Купить',
      'The Sold-Out Seat': 'Проданный место',
      'You can select up to @@count  seats.':
        'Вы можете выбрать до @@count мест.',
      'Available Seat': 'Доступное место',
      'Selected Seats': 'Выбранные места',
      'Selected Seat': 'Выбранное место',
      'Clear Selected Seats': 'Очистить выбранные места',
      'Out Of Sales': 'Вне продаж',
      'Incompatible Ticket Type': 'Несовместимый тип билета',
      'Not Found Phone': 'Телефон не найден',
      'The entrance has been made': 'Вход был сделан',
      'Could not log in': 'Не удалось войти',
      'The SMS code was entered incorrectly.':
        'Код SMS был введен неправильно.',
      'This number is not registered': 'Этот номер не зарегистрирован',
      'Sms Was Sent.': 'Смс был отправлен',
      'Sms Could Not Be Sent': 'Смс не может быть отправлено',
      'Please enter a valid phone number.':
        'Пожалуйста, введите действительный номер телефона.',
      'The password has been sent': 'Пароль был отправлен',
      'The password could not be sent. Please try again later.':
        'Пароль не может быть отправлен. Пожалуйста, попробуйте позже.',
      'Registration is successful': 'Регистрация прошла успешно',
      'Please check the information you have entered.':
        'Пожалуйста, проверьте введенную информацию.',
      'Registration failed': 'Регистрация не удалась',
      'Please fill in the required fields':
        'Пожалуйста, заполните обязательные поля',
      'No ticket was found': 'Билет не был найден',
      'Please check the check box.': 'Пожалуйста, проверьте флажок.',
      'Please check the form.': 'Пожалуйста, проверьте форму.',
      'Your basket has been cleaned.': 'Ваша корзина была очищена.',
      'Later, your purchase request was successfully registered.':
        'Позже ваш запрос на покупку был успешно зарегистрирован.',
      'An error occurred during the process. Please try again.':
        'Во время процесса произошла ошибка. Пожалуйста, попробуйте еще раз.',
      'You can get a maximum of @@count from the @@name ticket in your cart.':
        'Вы можете получить максимум @@count из билета @@name в вашей корзине.',
      'This discount does not apply to the product with the promo code in your cart.':
        'Эта скидка не распространяется на продукт с промо-кодом в вашей корзине.',
      'The Reservation Could not be Found.':
        'Бронирование не может быть найдено.',
      'Odeme Could not be Found.': 'Одеме не может быть найден.',
      'Pay Failed.': 'Оплата не удалась.',
      'The reservation could not be saved. Please let us know.':
        'Бронирование не может быть сохранено. Пожалуйста, дайте нам знать.',
      'E-Mail Has Been Sent': 'Электронная почта была отправлена',
      'The Mail Could not be Sent.': 'Почта не может быть отправлена.',
      'There are no products in your cart.': 'В вашей корзине нет товаров.',
      'The Pay Could not be Completed.Let us know.':
        'Оплата не может быть завершена. Дайте нам знать.',
      'PNR not found': 'PNR не найден',
      'An error has occurred. Please try again.':
        'Произошла ошибка. Пожалуйста, попробуйте еще раз.',
      'The concert could not be found.': 'Концерт не может быть найден.',
      'Please select the number of tickets.':
        'Пожалуйста, выберите количество билетов.',
      'The quota is full.': 'Квота заполнена.',
      'You cannot purchase more than the quota.':
        'Вы не можете приобрести больше квоты.',
      'Please enter the promotional code.': 'Пожалуйста, введите промо-код.',
      'The promo code could not be found': 'Промо-код не может быть найден',
      'The promo code @@beforeName is not valid for the ticket type. Changed to @@newName.':
        'Промо-код @@beforeName не действителен для типа билета. Изменено на @@newName.',
      'Seat selection is successful': 'Выбор места успешен',
      'The seat selection has been cancelled.': 'Выбор места был отменен.',
      'This seat is unelectable. Change the ticket type.':
        'Это место невыбираемое. Измените тип билета.',
      'This seat has been sold': 'Это место продано',
      'This seat is defective': 'Это место неисправно',
      'Please select @@name number of seats':
        'Пожалуйста, выберите @@name количество мест',
      'Please select the MOQ of the product.':
        'Пожалуйста, выберите MOQ продукта.',
      'No product was found in your cart. Please add the product.':
        'В вашей корзине не найдено ни одного продукта. Пожалуйста, добавьте продукт.',
      'The ticket was added to the cart.': 'Билет был добавлен в корзину.',
      'At least 1 ticket must be selected.':
        'Необходимо выбрать не менее 1 билета.',
      'No tickets were found for the date you selected, please change the date.':
        'Билеты не были найдены для выбранной вами даты, пожалуйста, измените дату.',
      'Tickets are only available on @@message day.':
        'Билеты доступны только в день @@message.',
      'Please enter a valid Turkish ID number.':
        'Пожалуйста, введите действительный турецкий идентификационный номер.',
      'An error occurred while filling in the card information.':
        'Произошла ошибка при заполнении информации о карте.',
      'The contract could not be found.': 'Контракт не может быть найден.',
      'Already logged in': 'Уже вошел в систему',
      'The selected ticket could not be found. Please select a ticket.':
        'Выбранный билет не может быть найден. Пожалуйста, выберите билет.',
      'Please log in first to enter the profile.':
        'Пожалуйста, сначала войдите в профиль.',
      'No product was found in your cart. Please add product.':
        'В вашей корзине не найдено ни одного продукта. Пожалуйста, добавьте продукт.',
      'Concerts with Different Dates Cannot be Added to the Cart':
        'Концерты с разными датами не могут быть добавлены в корзину',
      'The Concert Was Added to the Cart': 'Концерт был добавлен в корзину',
      'Extra Products have Been Added to the Cart':
        'Дополнительные продукты были добавлены в корзину',
      'The Extra Product Has Been Deleted from the Cart':
        'Дополнительный продукт был удален из корзины',
      'The Product Was Deleted from the Cart': 'Продукт был удален из корзины',
      'The appropriate payment options for your card could not be obtained. Please try again later.':
        'Подходящие варианты оплаты для вашей карты не могут быть получены. Пожалуйста, попробуйте позже.',
      'An error occurred while saving your cart.Please try again soon.':
        'Произошла ошибка при сохранении вашей корзины. Пожалуйста, попробуйте снова в ближайшее время.',
      'You can buy a maximum of @@quota tickets.':
        'Вы можете купить максимум @@quota билетов.',
      'Please check your card information.':
        'Пожалуйста, проверьте информацию о вашей карте.',
      'Pay Online': 'Оплатить онлайн',
      QUANTITY: 'Количество',
    };
    this.EN = {
      NO_CONCERT_TEXT_1: 'Exciting Events Are On The Way!',
      NO_CONCERT_TEXT_2:
        'Şu an için aktif bir biletimiz bulunmuvor, ancak endişelenmevin! Sizler için dopdolu bir etkinlik takvimi üzerinde calisiyoruz ve yakinda harika firsatlarla geri dönecediz!',
      NO_CONCERT_TEXT_3:
        'Dont forget to follow us so as not to miss our new tickets when they are on sale again.',
      Events: 'Events',
      'Add Events': 'Add Events',
      AboutUs: 'About Us',
      'Post Code': 'Post Code',
      KVKK: 'GDPR',
      'Grouped Concert List': 'Grouped Concert List',
      'Go to the Cart': 'Go to the Cart',
      'Seats Attached to The Basket': 'Seats Attached to The Basket',
      Yetişkin: 'Adult',
      Çocuk: 'Child',
      'Confirm the Seat Selection': 'Confirm the Seat Selection',
      'Confirm the Seat Selection and Go to the Cart':
        'Confirm the Seat Selection and Go to the Cart',
      'Event End Hour': 'Event End Hour',
      'Event Start Hour': 'Event Start Hour',
      'Person Count': 'Person Count',
      'Event Date': 'Event Date',
      'Stadium entrance is free when you buy a park entrance ticket':
        'Stadium entrance is free when you buy a park entrance ticket',
      'An error occurred while registering the ticket. Please try again.':
        'An error occurred while registering the ticket. Please try again.',
      'Please choose a date': 'Please choose a date',
      'the 15-minute timeout has expired. Please select the ticket again.':
        'the 15-minute timeout has expired. Please select the ticket again.',
      'This seat is closed for sale': 'This seat is closed for sale',
      'Choose Language': 'Choose Language',
      'Seat Numbers': 'Seat Numbers',
      'Other Categories': 'Other Categories',
      Dear: 'Dear',
      'Your reservation has been created. You can participate in the event with a QR code':
        'Your reservation has been created. You can participate in the event with a QR code',
      'All seats selected': 'All seats selected',
      Back: 'Back',
      Home: 'Home page',
      'Ticket Date': 'Ticket Date',
      'Pnr Number': 'PNR Number',
      'Contact Information': 'Contact Information',
      Name: 'Name',
      Surname: 'Surname',
      TicketInformation: 'Ticket Information',
      'Ticket Count': 'Ticket Count',
      'Total Price': 'Total Price',
      Basket: 'Basket',
      'Use Agreement': 'Use Agreement',
      'Read And Accept': 'Read And Accept',
      'Complete The Purchase': 'Complete The Purchase',
      'Continue Reservation': 'Continue Reservation',
      'Buy Later': 'Buy Later',
      'Clear Basket': 'Clear Basket',
      Free: 'Free',
      'Bill Information': 'Bill Information',
      Individual: 'Individual',
      Corporate: 'Corporate',
      City: 'City',
      Citysub: 'Town',
      'Select City': 'Please select a city',
      'Savecheck Error':
        'Error has occurred. Please refresh the page and try again. If the same problem occurs, let us know with the Page link or PNR number.',
      'Send Sms and Email': 'Sending SMS and Mail, please wait.',
      Email: 'Email',
      Phone: 'Phone',
      'Tax Number': 'Tax Number',
      'Zip Code': 'Zip Code',
      'Credit Card Declined': 'Credit Card Declined',
      LBL_CARDERROR_FIRSTLINE: 'Your payment could not be made.',
      LBL_CARDERROR_SECONDLINE: 'Your Credit Card Has Been Rejected.',
      LBL_CARDERROR_THIRDLINE:
        'Your transaction could not be completed. We are waiting for your payment to confirm your reservation, please note that once you complete the payment, your reservation will be confirmed and you will receive your document instantly.',
      LBL_CARDERROR_FOURTHLINE: 'Your card may have a limit',
      LBL_CARDERROR_FIFTHLINE: 'It may be blocked by your bank',
      LBL_CARDERROR_SIXTHLINE:
        'Your payment may have exceeded your daily limit',
      LBL_CARDERROR_SEVENTHLINE: 'You can try with a debit or other card.',
      LBL_CARDERROR_EIGHTHLINE: 'Your credit card may have been canceled',
      LBL_CARDERROR_NINETHLINE:
        'There may be a special situation between you and the bank, in which case you should contact your bank.',
      'Concert Information': 'Concert Information',
      'Invoice Info': 'Invoice Info',
      'Extra Services Information': 'Extra Services Information',
      PARK_CLOSE_SELL1: 'Our facility is temporarily closed for sale.',
      PARK_CLOSE_SELL2: 'Please with us',
      PARK_CLOSE_SELL3: 'Click Here',
      PARK_CLOSE_SELL4: 'or',
      PARK_CLOSE_SELL5: 'Contact us by calling.',
      PARK_CLOSE_SELL6: 'Thank you for your understanding.',
      Info: 'Info',
      Agreements: 'Agreements',
      Links: 'Links',
      Contact: 'Contact',
      'Pay Up': 'Pay',
      'Card Holder': 'Card Holder',
      'Card Number': 'Card Number',
      'Not Found Ticket Date':
        'The card number was not found on the specified date.',
      'Add to Cart': 'Add to Cart',
      Close: 'Close',
      Children: 'Children',
      Adult: 'Adult',
      Date: 'Date',
      'Select Date': 'Please select the date you want to come to the facility.',
      'Go to Basket': 'Go to Basket',
      'Extra Services': 'Extra Service',
      Total: 'Total',
      'Promotion Code Error': 'Promotion Code Error',
      'Promotion Code': 'Promotion Code',
      'Ticket Type': 'Ticket Type',
      'Concert Date': 'Concert Date',
      'Concert End Hour': 'Concert End Hour',
      'Concert Start Hour': 'Concert Start Hour',
      Location: 'Location',
      Rules: 'Rules',
      Detail: 'Detail',
      'Concert List': 'Concert List',
      Logout: 'Logout',
      Profile: 'Profile',
      Register: 'Register',
      Login: 'Login',
      Concerts: 'Concerts',
      Tickets: 'Tickets',
      Ticket: 'Ticket',
      'Ticket Name': 'Ticket Name',
      Price: 'Price',
      'Identity Number': 'Identity Number',
      Address: 'Address',
      BTN_PDF: 'Download as PDF',
      FOOTER_PRIVACY_AGREEMENT: 'Confidentiality agreement',
      FOOTER_SALE_AGREEMENT: 'Sales Agreement',
      CancellationAgreement: 'Cancellation Agreement',
      Buy: 'Buy',
      'The Sold-Out Seat': 'The Sold-Out Seat',
      'You can select up to @@count  seats.':
        'You can select up to @@count  seats.',
      'Available Seat': 'Available Seat',
      'Selected Seats': 'Selected Seats',
      'Selected Seat': ' Selected Seat',
      'Clear Selected Seats': 'Clear Selected Seats',
      'Out Of Sales': 'Out Of Sales',
      'Incompatible Ticket Type': 'Incompatible Ticket Type',
      'Not Found Phone': 'Not Found Phone',
      'The entrance has been made': 'The entrance has been made',
      'Could not log in': 'Could not log in',
      'The SMS code was entered incorrectly.':
        'The SMS code was entered incorrectly.',
      'This number is not registered': 'This number is not registered',
      'Sms Was Sent.': 'Sms Was Sent.',
      'Sms Could Not Be Sent': 'Sms Could Not Be Sent',
      'Please enter a valid phone number.':
        'Please enter a valid phone number.',
      'The password has been sent': 'The password has been sent',
      'The password could not be sent. Please try again later.':
        'The password could not be sent. Please try again later.',
      'Registration is successful': 'Registration is successful',
      'Please check the information you have entered.':
        'Please check the information you have entered.',
      'Registration failed': 'Registration failed',
      'Please fill in the required fields':
        'Please fill in the required fields',
      'No ticket was found': 'No ticket was found',
      'Please check the check box.': 'Please check the check box.',
      'Please check the form.': 'Please check the form.',
      'Your basket has been cleaned.': 'Your basket has been cleaned.',
      'Later, your purchase request was successfully registered.':
        'Later, your purchase request was successfully registered.',
      'An error occurred during the process. Please try again.':
        'An error occurred during the process. Please try again.',
      'You can get a maximum of @@count from the @@name ticket in your cart.':
        'You can get a maximum of @@count from the @@name ticket in your cart.',
      'This discount does not apply to the product with the promo code in your cart.':
        'This discount does not apply to the product with the promo code in your cart.',
      'The Reservation Could not be Found.':
        'The Reservation Could not be Found.',
      'Odeme Could not be Found.': 'Payment Not Found.',
      'Pay Failed.': 'Pay Failed.',
      'The reservation could not be saved. Please let us know.':
        'The reservation could not be saved. Please let us know.',
      'E-Mail Has Been Sent': 'E-Mail Has Been Sent',
      'The Mail Could not be Sent.': 'The Mail Could not be Sent.',
      'There are no products in your cart.':
        'There are no products in your cart.',
      'The Pay Could not be Completed.Let us know.':
        'The Pay Could not be Completed.Let us know.',
      'PNR not found': 'PNR not found',
      'An error has occurred. Please try again.':
        'An error has occurred. Please try again.',
      'The concert could not be found.': 'The concert could not be found.',
      'Please select the number of tickets.':
        'Please select the number of tickets.',
      'The quota is full.': 'The quota is full.',
      'You cannot purchase more than the quota.':
        'You cannot purchase more than the quota.',
      'Please enter the promotional code.':
        'Please enter the promotional code.',
      'The promo code could not be found': 'The promo code could not be found',
      'The promo code @@beforeName is not valid for the ticket type. Changed to @@newName.':
        'The promo code @@beforeName is not valid for the ticket type. Changed to @@newName.',
      'Seat selection is successful': 'Seat selection is successful',
      'The seat selection has been cancelled.':
        'The seat selection has been cancelled.',
      'This seat is unelectable. Change the ticket type.':
        'This seat is unelectable. Change the ticket type.',
      'This seat has been sold': 'This seat has been sold',
      'This seat is defective': 'This seat is defective',
      'Please select @@name number of seats':
        'Please select @@name number of seats',
      'Please select the MOQ of the product.':
        'Please select the number of items.',
      'No product was found in your cart. Please add the product.':
        'No product was found in your cart. Please add the product.',
      'The ticket was added to the cart.': 'The ticket was added to the cart.',
      'At least 1 ticket must be selected.':
        'At least 1 ticket must be selected.',
      'No tickets were found for the date you selected, please change the date.':
        'No tickets were found for the date you selected, please change the date.',
      'Tickets are only available on @@message day.':
        'Tickets are only available on @@message day.',
      'Please enter a valid Turkish ID number.':
        'Please enter a valid Turkish ID number.',
      'An error occurred while filling in the card information.':
        'An error occurred while filling in the card information.',
      'The contract could not be found.': 'The contract could not be found.',
      'Already logged in': 'Already logged in',
      'The selected ticket could not be found. Please select a ticket.':
        'The selected ticket could not be found. Please select a ticket.',
      'Please log in first to enter the profile.':
        'Please log in first to enter the profile.',
      'No product was found in your cart. Please add product.':
        'No product was found in your cart. Please add product.',
      'Concerts with Different Dates Cannot be Added to the Cart':
        'Concerts with Different Dates Cannot be Added to the Cart',
      'The Concert Was Added to the Cart': 'The Concert Was Added to the Cart',
      'Extra Products have Been Added to the Cart':
        'Extra Products have Been Added to the Cart',
      'The Extra Product Has Been Deleted from the Cart':
        'The Extra Product Has Been Deleted from the Cart',
      'The Product Was Deleted from the Cart':
        'The Product Was Deleted from the Cart',
      'The appropriate payment options for your card could not be obtained. Please try again later.':
        'The appropriate payment options for your card could not be obtained. Please try again later.',
      'An error occurred while saving your cart.Please try again soon.':
        'An error occurred while saving your cart.Please try again soon.',
      'You can buy a maximum of @@quota tickets.':
        'You can buy a maximum of @@quota tickets.',
      'Please check your card information.':
        'Please check your card information.',
      'Pay Online': 'Pay Online',
      QUANTITY: 'QUANTITY',
      'You have the right to choose @@count seats':
        'You have the right to choose @@count seats',
    };
    this.DE = {
      NO_CONCERT_TEXT_1: 'Spannende Veranstaltungen sind auf dem Weg!',
      NO_CONCERT_TEXT_2:
        'Şu an için aktiv bir biletimiz bulunmuvor, ancak endişelenmevin! Sizler için dopdolu bir etkinligi takvimi üzerinde calisiyoruz und yakinda harika haben zuerst geri dönecediz getötet!',
      NO_CONCERT_TEXT_3:
        'Vergessen Sie nicht, uns zu folgen, um unsere neuen Tickets nicht zu verpassen, wenn sie wieder im Verkauf sind.',
      Events: 'Veranstaltungen',
      'Add Events': 'Veranstaltungen hinzufügen',
      AboutUs: 'Über uns',
      'Post Code': 'Postleitzahl',
      KVKK: 'GDPR',
      'Grouped Concert List': 'Gruppierte Konzertliste',
      'Go to the Cart': 'Zum Warenkorb',
      'Seats Attached to The Basket': 'An den Korb angehängte Sitze',
      Yetişkin: 'Erwachsener',
      Çocuk: 'Kind',
      'Confirm the Seat Selection': 'Bestätigen Sie die Sitzplatzwahl',
      'Confirm the Seat Selection and Go to the Cart':
        'Bestätigen Sie die Sitzplatzwahl und gehen Sie zum Warenkorb',
      'Event End Hour': 'Event End Hour',
      'Event Start Hour': 'Event Start Hour',
      'Person Count': 'Personenzahl',
      'Event Date': 'Veranstaltungsdatum',
      'Stadium entrance is free when you buy a park entrance ticket':
        'Stadioneingang ist frei, wenn Sie ein Park-Eingangsticket kaufen',
      'An error occurred while registering the ticket. Please try again.':
        'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.',
      'Please choose a date': 'Bitte wählen Sie ein Datum',
      'the 15-minute timeout has expired. Please select the ticket again.':
        'Die 15-minütige Zeitüberschreitung ist abgelaufen. Bitte wählen Sie das Ticket erneut aus.',
      'Other Categories': 'Andere Kategorien',
      'This seat is closed for sale':
        'Dieser Sitz ist für den Verkauf geschlossen',

      'Choose Language': 'Sprache wählen',
      'Seat Numbers': 'Sitznummern',
      Dear: 'Sehr geehrte',
      'Your reservation has been created. You can participate in the event with a QR code':
        'Ihre Reservierung wurde erstellt. Sie können an der Veranstaltung mit einem QR-Code teilnehmen',
      'All seats selected': 'Alle Sitze ausgewählt',
      Back: 'Zurück',
      Home: 'Startseite',
      'Ticket Date': 'Ticket Datum',
      'Pnr Number': 'PNR-Nummer',
      Name: 'Name',
      Surname: 'Nachname',
      TicketInformation: 'Ticket informationen',
      'Ticket Count': 'Ticket anzahl',
      'Total Price': 'Gesamtpreis',
      Basket: 'Korb',
      'Use Agreement': 'Nutzungsvereinbarung',
      'Read And Accept': 'Lesen und akzeptieren',
      'Complete The Purchase': 'Schließen Sie den Kauf ab',
      'Continue Reservation': 'Reservierung fortsetzen',
      'Buy Later': 'Später kaufen',
      'Clear Basket': 'Klarer Korb',
      Free: 'Frei',
      'Contact Information': 'Kontakt informationen',
      'Bill Information': 'Rechnungs informationen',
      Individual: 'Individuell',
      Corporate: 'Korporativ',
      City: 'Stadt',
      Citysub: 'Bezirk',
      'Select City': 'Bitte wählen Sie eine Stadt aus',
      'Savecheck Error':
        'Ein Fehler ist aufgetreten. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut. Wenn das gleiche Problem auftritt, teilen Sie uns dies mit dem Seitenlink oder der PNR-Nummer mit.',
      'Send Sms and Email': 'SMS und E-Mail werden gesendet, bitte warten.',
      Email: 'Email',
      Phone: 'Telephone',
      'Tax Number': 'Steuer nummer',
      'Zip Code': 'Postleitzahl',
      'Credit Card Declined': 'Kreditkarte abgelehnt',
      LBL_CARDERROR_FIRSTLINE: 'Ihre Zahlung konnte nicht erfolgen.',
      LBL_CARDERROR_SECONDLINE: 'Ihre Kreditkarte wurde abgelehnt.',
      LBL_CARDERROR_THIRDLINE:
        'Ihre Transaktion konnte nicht abgeschlossen werden. Wir warten auf Ihre Zahlung, um Ihre Reservierung zu bestätigen. Bitte beachten Sie, dass Ihre Reservierung bestätigt wird und Sie Ihr Dokument sofort erhalten, sobald Sie die Zahlung abgeschlossen haben.',
      LBL_CARDERROR_FOURTHLINE:
        'Ihre Karte verfügt möglicherweise über ein Limit',
      LBL_CARDERROR_FIFTHLINE: 'Möglicherweise von Ihrer Bank blockiert',
      LBL_CARDERROR_SIXTHLINE:
        'Möglicherweise hat Ihre Zahlung Ihr Tageslimit überschritten',
      LBL_CARDERROR_SEVENTHLINE:
        'Sie können es mit einer Debitkarte oder einer anderen Karte versuchen.',
      LBL_CARDERROR_EIGHTHLINE:
        'Möglicherweise wurde Ihre Kreditkarte storniert',
      LBL_CARDERROR_NINETHLINE:
        'Zwischen Ihnen und der Bank kann eine besondere Situation vorliegen. In diesem Fall sollten Sie sich an Ihre Bank wenden.',
      'Concert Information': 'Konzert informationen',
      'Invoice Info': 'Rechnungs informationen',
      'Extra Services Information':
        'Informationen zu zusätzlichen Dienstleistungen',
      PARK_CLOSE_SELL1:
        'Unsere Anlage ist wegen Verkauf vorübergehend geschlossen.',
      PARK_CLOSE_SELL2: 'Bitte mit uns',
      PARK_CLOSE_SELL3: 'Klicken Sie hier',
      PARK_CLOSE_SELL4: 'oder',
      PARK_CLOSE_SELL5: 'Kontaktieren Sie uns telefonisch.',
      PARK_CLOSE_SELL6: 'Danke für Ihr Verständnis.',
      Info: 'Die Info',
      Agreements: 'Verträge',
      Links: 'Link',
      Contact: 'Kontak',
      'Pay Up': 'Bezahlen',
      'Card Holder': 'Kartenhalter',
      'Card Number': 'Kartennummer',
      'Not Found Ticket Date':
        'Die Kartennummer wurde zum angegebenen Datum nicht gefunden.',
      'Add to Cart': 'in den Warenkorb legen',
      Close: 'Schließen',
      Children: 'Kinder',
      Adult: 'Erwachsene',
      Date: 'Datum',
      'Select Date':
        'Bitte wählen Sie das Datum aus, an dem sie in die Unterkunft kommen möchten.',
      'Go to Basket': 'Gehen Sie zum Warenkorb',
      'Extra Services': 'Extra-Service',
      Total: 'Gesamt',
      'Promotion Code Error': 'Fehler beim Aktionscode',
      'Promotion Code': 'Aktionscode',
      'Ticket Type': 'Art des Tickets',
      'Concert Date': 'Konzertdatum',
      'Concert End Hour': 'Ende Des Konzerts',
      'Concert Start Hour': 'Konzert beginn',
      Location: 'Standort',
      Rules: 'Regeln',
      Detail: 'Detail',
      'Concert List': 'Konzertliste',
      Logout: 'Ausloggen',
      Profile: 'Profil',
      Register: 'Registrieren',
      Login: 'Anmeldung',
      Concerts: 'Konzerte',
      Tickets: 'Tickets',
      Ticket: 'Ticket',
      'Ticket Name': 'Ticket Name',
      Price: 'Preis',
      'Identity Number': 'Identifikationsnummer',
      Address: 'Adresse',
      BTN_PDF: 'Als PDF herunterladen',
      FOOTER_PRIVACY_AGREEMENT: 'Verschwiegenheitserklärung',
      FOOTER_SALE_AGREEMENT: 'Kaufvertrag',
      CancellationAgreement: 'Stornierungsvereinbarung',
      Buy: 'Kaufen',
      'The Sold-Out Seat': 'Der ausverkaufte Sitz',
      'You can select up to @@count  seats.':
        'Sie können bis zu @@count Sitze auswählen.',
      'Available Seat': 'Verfügbarer Sitz',
      'Selected Seats': 'Ausgewählte Sitze',
      'Selected Seat': 'Ausgewählter Sitz',
      'Clear Selected Seats': 'Ausgewählte Sitze löschen',
      'Out Of Sales': 'Ausverkauft',
      'Incompatible Ticket Type': 'Inkompatibler Tickettyp',
      'Not Found Phone': 'Telefon nicht gefunden',
      'The entrance has been made': 'Der Eingang ist gemacht',
      'Could not log in': 'Konnte nicht einloggen',
      'The SMS code was entered incorrectly.':
        'Der SMS-Code wurde falsch eingegeben.',
      'This number is not registered': 'Diese Nummer ist nicht registriert',
      'Sms Was Sent.': 'SMS wurde gesendet.',
      'Sms Could Not Be Sent': 'SMS konnte nicht gesendet werden',
      'Please enter a valid phone number.':
        'Bitte geben Sie eine gültige Telefonnummer ein.',
      'The password has been sent': 'Das Passwort wurde gesendet',
      'The password could not be sent. Please try again later.':
        'Das Passwort konnte nicht gesendet werden. Bitte versuchen Sie es später noch einmal.',
      'Registration is successful': 'Die Registrierung ist erfolgreich',
      'Please check the information you have entered.':
        'Bitte überprüfen Sie die von Ihnen eingegebenen Informationen.',
      'Registration failed': 'Registrierung fehlgeschlagen',
      'Please fill in the required fields':
        'Bitte fülle die notwendigen Felder aus',
      'No ticket was found': 'Es wurde kein Ticket gefunden',
      'Please check the check box.':
        'Bitte aktivieren Sie das Kontrollkästchen.',
      'Please check the form.': 'Bitte überprüfen Sie das Formular.',
      'Your basket has been cleaned.': 'Ihr Warenkorb wurde gereinigt.',
      'Later, your purchase request was successfully registered.':
        'Später wurde Ihre Kaufanfrage erfolgreich registriert.',
      'An error occurred during the process. Please try again.':
        'Während des Vorgangs ist ein Fehler aufgetreten. Bitte versuche es erneut.',
      'You can get a maximum of @@count from the @@name ticket in your cart.':
        'Sie können maximal @@count vom @@name-Ticket in Ihrem Warenkorb erhalten.',
      'This discount does not apply to the product with the promo code in your cart.':
        'Dieser Rabatt gilt nicht für das Produkt mit dem Aktionscode in Ihrem Warenkorb.',
      'The Reservation Could not be Found.':
        'Die Reservierung konnte nicht gefunden werden.',
      'Odeme Could not be Found.': 'Zahlung nicht gefunden.',
      'Pay Failed.': 'Zahlung fehlgeschlagen.',
      'The reservation could not be saved. Please let us know.':
        'Die Reservierung konnte nicht gespeichert werden. Informieren Sie uns bitte.',
      'E-Mail Has Been Sent': 'Die Email wurde verschickt',
      'The Mail Could not be Sent.': 'Die E-Mail konnte nicht gesendet werden.',
      'There are no products in your cart.':
        'Es befinden sich keine Produkte in Ihrem Warenkorb.',
      'The Pay Could not be Completed.Let us know.':
        'Die Zahlung konnte nicht abgeschlossen werden. Lassen Sie es uns wissen.',
      'PNR not found': 'PNR nicht gefunden',
      'An error has occurred. Please try again.':
        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
      'The concert could not be found.':
        'Das Konzert konnte nicht gefunden werden.',
      'Please select the number of tickets.':
        'Bitte wählen Sie die Anzahl der Tickets aus.',
      'The quota is full.': 'Das Kontingent ist voll.',
      'You cannot purchase more than the quota.':
        'Sie können nicht mehr als das Kontingent kaufen.',
      'Please enter the promotional code.':
        'Bitte geben Sie den Aktionscode ein.',
      'The promo code could not be found':
        'Bitte geben Sie den Aktionscode ein.',
      'The promo code @@beforeName is not valid for the ticket type. Changed to @@newName.':
        'Der Aktionscode @@beforeName ist für den Tickettyp nicht gültig. Geändert in @@newName.',
      'Seat selection is successful': 'Die Sitzplatzauswahl ist erfolgreich',
      'The seat selection has been cancelled.':
        'Die Sitzplatzauswahl wurde abgesagt.',
      'This seat is unelectable. Change the ticket type.':
        'Dieser Sitz ist nicht wählbar. Ändern Sie den Tickettyp.',
      'This seat has been sold': 'Dieser Sitz wurde verkauft',
      'This seat is defective': 'Dieser Sitz ist defekt',
      'Please select @@name number of seats':
        'Bitte wählen Sie @@Name Anzahl der Sitzplätze aus',
      'Please select the MOQ of the product.':
        'Bitte wählen Sie die Anzahl der Artikel aus.',
      'No product was found in your cart. Please add the product.':
        'In Ihrem Warenkorb wurde kein Produkt gefunden. Bitte fügen Sie das Produkt hinzu.',
      'The ticket was added to the cart.':
        'Das Ticket wurde dem Warenkorb hinzugefügt.',
      'At least 1 ticket must be selected.':
        'Es muss mindestens 1 Ticket ausgewählt werden.',
      'No tickets were found for the date you selected, please change the date.':
        'Für das von Ihnen ausgewählte Datum wurden keine Tickets gefunden. Bitte ändern Sie das Datum.',
      'Tickets are only available on @@message day.':
        'Tickets sind nur am @@message Tag verfügbar.',
      'Please enter a valid Turkish ID number.':
        'Bitte geben Sie eine gültige türkische Ausweisnummer ein.',
      'An error occurred while filling in the card information.':
        'Beim Ausfüllen der Kartendaten ist ein Fehler aufgetreten.',
      'The contract could not be found.':
        'Der Vertrag konnte nicht gefunden werden.',
      'Already logged in': 'Bereits eingeloggt',
      'The selected ticket could not be found. Please select a ticket.':
        'Das ausgewählte Ticket konnte nicht gefunden werden. Bitte wählen Sie ein Ticket aus.',
      'Please log in first to enter the profile.':
        'Bitte melden Sie sich zuerst an, um das Profil einzugeben.',
      'No product was found in your cart. Please add product.':
        'In Ihrem Warenkorb wurde kein Produkt gefunden. Bitte fügen Sie das Produkt hinzu.',
      'Concerts with Different Dates Cannot be Added to the Cart':
        'Konzerte mit unterschiedlichen Daten können nicht zum Warenkorb hinzugefügt werden',
      'The Concert Was Added to the Cart':
        'Das Konzert wurde dem Warenkorb hinzugefügt',
      'Extra Products have Been Added to the Cart':
        'Zusatzprodukte wurden dem Warenkorb hinzugefügt',
      'The Extra Product Has Been Deleted from the Cart':
        'Das Zusatzprodukt wurde aus dem Warenkorb gelöscht',
      'The Product Was Deleted from the Cart':
        'Das Produkt wurde aus dem Warenkorb gelöscht',
      'The appropriate payment options for your card could not be obtained. Please try again later.':
        'Die geeigneten Zahlungsoptionen für Ihre Karte konnten nicht abgerufen werden. Bitte versuchen Sie es später noch einmal.',
      'An error occurred while saving your cart.Please try again soon.':
        'Beim Speichern Ihres Warenkorbs ist ein Fehler aufgetreten. Bitte versuchen Sie es bald wieder.',
      'You can buy a maximum of @@quota tickets.':
        'Sie können maximal @@quota Tickets kaufen.',
      'Please check your card information.':
        'Bitte überprüfen Sie Ihre Kartendaten.',
      'Pay Online': 'Online bezahlen',
      QUANTITY: 'MENGE',
      'You have the right to choose @@count seats':
        'Sie haben das Recht, @@count Sitze zu wählen',
    };
  }
}
